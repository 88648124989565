
import { useProduct } from '@/modules/version-manage';
import { defineComponent, reactive, toRefs, ref, watch, onActivated } from 'vue';
import { getProductList, getBranchDetail, editProductBranch, addProductBranch } from '@/api/version-manage';
import { AsyncReturnType } from '@/@types';
import { DeepTransArray } from '@/tools/common';

export default defineComponent({
  components: {},
  props: {
    /** 是否显示 */
    visible: {
      type: Boolean,
      default: false,
    },
    /** 分支id */
    branchId: {
      type: String,
      default: '',
    },
    /** 是否禁用 */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const global = useProduct();

    const formRef = ref();

    const state = reactive({
      /** 已选择来源 */
      selectedSourceList: [] as Record<string, any>[],
      /** 来源列表 */
      sourceList: [] as Record<string, any>[],
      /** 是否加载中 */
      isLoading: false,
      /** 作者列表 */
      authorList: [] as string[],
      /** 表单 */
      form: {
        productId: '',
        branchName: '',
        description: '',
      },
      /** 表单规则 */
      rules: {
        branchName: [
          { required: true, message: '请输入分支名称' },
          { min: 3, message: '分支名称至少超过3位' },
          { pattern: /^[a-zA-Z0-9_]+$/g, message: '分支名称只能由英文字母/数字/下划线组成' },
        ],
        productId: [{ required: true, message: '请选择关联产品' }],
        description: [{ required: true, message: '请输入分支描述' }],
      },
      /** 产品列表 */
      productList: [] as AsyncReturnType<typeof getProductList>['rows'],
    });

    /** 清空数据 */
    const clear = () => {
      state.form = {
        productId: '',
        branchName: '',
        description: '',
      };
    };

    onActivated(() => {
      // 查询产品列表
      getProductList({
        pageNum: 1,
        pageSize: 9999,
      })
        .then(data => {
          if (data) {
            state.productList = DeepTransArray(data.rows, item => ({
              ...item,
              id: String(item.id),
            }));
          }
        })
        .finally(() => {
          state.isLoading = false;
        });
    });

    watch(
      () => props.visible,
      (count, prevCount) => {
        if (count) {
          if (props.branchId) {
            state.isLoading = true;
            getBranchDetail({
              id: Number(props.branchId),
            })
              .then(data => {
                if (data) {
                  // @ts-ignore
                  state.form = {
                    productId: `${data.productId}`,
                    branchName: data.branchName,
                    description: data.description,
                  };
                }
              })
              .finally(() => {
                state.isLoading = false;
              });
          } else {
            clear();
          }
        }
      },
    );

    return {
      ...toRefs(state),
      global,
      formRef,
      clear,
    };
  },
  created() {},
  mounted() {},
  methods: {
    onClose() {
      this.clear();
      this.$emit('update:visible', false);
    },
    onSubmit() {
      this.isLoading = true;
      this.formRef
        .validate()
        .then(() => {
          if (this.branchId) {
            editProductBranch({
              ...this.form,
              id: Number(this.branchId),
            })
              .then(d => {
                this.$emit('update:visible', false);
                this.$emit('submit', d);
              })
              .finally(() => {
                this.isLoading = false;
              });
          } else {
            addProductBranch({
              ...this.form,
            })
              .then(d => {
                this.$emit('update:visible', false);
                this.$emit('submit', d);
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        })
        .catch(error => {
          this.isLoading = false;
          console.error('error', error);
        });
    },
  },
});
