import { AnyDataSource } from '@/@types/version-manage/data-source';
import { DataSourceType } from '@/@types/version-manage/enum';
import { get } from '@/lib/api';
import { cloneLoop } from '@/lib/clone';
import { message } from 'ant-design-vue';

/** 获取变量类型 */
export function getType(val: any): string {
  return Object.prototype.toString.call(val).slice(8, -1).toLowerCase();
}

/** 打开问卷编辑器编辑或者预览页面 postmessage通用函数 */
export function postMsg(id: string | number, token: string, openWindow: any, questionarySrc: string, page: string) {
  if (openWindow) {
    openWindow.postMessage({ token, page: `${page}${id}` }, questionarySrc);
  }
}

/** 保留小数位 */
export function toDecimal(num: number | string, pos: number = 0): number {
  let re: number;
  if (typeof num === 'string') {
    if (num.includes('.')) {
      re = parseFloat(num);
    } else {
      return parseInt(num);
    }
  } else if (num.toString().includes('.')) {
    re = num;
  } else {
    return num;
  }
  if (Number.isNaN(re)) {
    return NaN;
  } else {
    return Math.round(re * 10 ** pos) / 10 ** pos;
  }
}

/** 节流函数 */
export function throttle(
  /** 需要节流的函数 */
  func: Function,
  /** 等待时长（毫秒） */
  wait = 300,
  /** 附加参数 */
  options: {
    /** 是否头部立刻执行 */
    leading: boolean;
    /** 是否尾部附加执行 */
    trailing: boolean;
  } = { leading: false, trailing: true },
) {
  let timer, result;
  let previous = 0;
  const _leading = options?.leading ?? false;
  const _trailing = options?.trailing ?? true;

  const throttled = async function (this: any, ...args) {
    const now = Date.now(); // 当前时间
    // 下次触发 func 剩余时间
    if (!previous && _leading === false) previous = now;
    const remaining = wait - (now - previous);

    // 如果没有剩余时间或者改了系统时间,这时候不需要等待，直接立即执行，这样就会第一次就执行
    if (remaining <= 0 || remaining > wait) {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      previous = now;
      // eslint-disable-next-line no-return-await
      return await func.apply(this, args);
    } else if (!timer && _trailing !== false) {
      // 剩余的情况就是remaining<=wait的情况，这里使用setTimeout就可以最后也会执行一次
      timer = setTimeout(async () => {
        timer = null;
        previous = _leading === false ? 0 : Date.now(); // 这里是将previous重新赋值当前时间
        // eslint-disable-next-line no-return-await
        return await func.apply(this, args);
      }, remaining);
    }
    return result;
  };

  throttled.cancel = () => {
    if (timer !== undefined) {
      clearTimeout(timer);
    }
    previous = 0;
    // eslint-disable-next-line no-multi-assign
    timer = result = undefined;
  };
  return throttled;
}

/** 获取Base64编码 */
export function getBase64(img: Blob | undefined, callback: (base64Url: string) => void) {
  if (!img) {
    throw new Error('img参数不能为空');
  }
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
}
/** 判断字符串是否符合json格式 */
export function judgeStringIsJson(str: string) {
  try {
    const obj = JSON.parse(str);
    if (typeof obj === 'object' && obj) {
      return true;
    } else {
      message.error('JSON格式错误');
      return false;
    }
  } catch (e) {
    message.error('JSON格式错误');
    return false;
  }
}

/** 移除对象中的属性 */
export function removeProp(obj: Record<string, any>, ...removePropNames: string[]) {
  let _obj = obj;
  removePropNames.forEach(propName => {
    const _keys = Object.keys(_obj).filter(i => i !== propName);
    _obj = Object.fromEntries(Object.entries(obj).filter(([key]) => _keys.includes(key)));
  });
  return _obj;
}

/** 移除空数据 */
export function clearBlankProps(params: Record<string, any> | Array<any>): Record<string, any> {
  if (Array.isArray(params)) {
    return params;
  }
  const _arr: Record<string, any>[] = Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== null && value !== '')
    .map(([key, value]) => ({
      [key]: value,
    }));
  return Object.assign.apply({}, [{}, ..._arr]);
}

/** 根据数据源获取数据 */
export function getDataSource<T = any>(
  dataSource: AnyDataSource,
  {
    pagination,
    filter,
  }: {
    pagination?:
      | {
          current: number;
          pageSize: number;
          total: number;
        }
      | undefined;
    filter?: Record<string, any>;
  } = {
    pagination: undefined,
    filter: {},
  },
): Promise<T> {
  return new Promise((resolve, reject) => {
    switch (dataSource.type) {
      case DataSourceType.json:
        // @ts-ignore
        resolve(JSON.parse(dataSource.value));
        break;
      case DataSourceType.api:
        // @ts-ignore
        get(dataSource.url, {
          ...filter,
          // @ts-ignore
          ...dataSource.params,
          pageSize: pagination?.pageSize,
          pageNum: pagination?.current,
        }).then(({ data }) => {
          // eslint-disable-next-line no-param-reassign
          if (pagination?.total !== undefined) pagination.total = data.total;
          resolve(data.list);
        });
        break;
      case DataSourceType.graphql:
        // get(props.data.dataSource.url, {
        //   pageNum: formState.pagination.current,
        //   pageSize: formState.pagination.pageSize,
        // }).then(({ data }) => {
        //   formState.dataSource = data.list;
        //   formState.pagination.total = data.total;
        // }).finally(() => {
        //   hide();
        //   formState.isLoading = false;
        // });
        break;
      default:
        break;
    }
  });
}

/** 睡眠函数 */
export function sleepWait(fn: () => Promise<boolean>, interval: number = 300, maxcount = 50): Promise<void> {
  let _current = 0;
  return new Promise((resolve, reject) => {
    const _sleep = () => {
      if (_current < maxcount) {
        setTimeout(() => {
          fn()
            .then(isOk => {
              if (isOk) {
                resolve();
              } else {
                _sleep();
              }
            })
            .catch(err => {
              _sleep();
            })
            .finally(() => {
              _current++;
            });
        }, interval);
      } else {
        reject(new Error(`尝试次数超过${maxcount}次`));
      }
    };
    _sleep();
  });
}
/** 睡眠函数 */
export function sleep(fn: () => boolean, interval = 300, maxcount = 50): Promise<void> {
  let _current = 0;
  return new Promise((resolve, reject) => {
    const _sleep = () => {
      if (_current < maxcount) {
        setTimeout(() => {
          if (fn()) {
            resolve();
          } else {
            _current++;
            _sleep();
          }
        }, interval);
      } else {
        reject(new Error(`尝试次数超过${maxcount}次`));
      }
    };
    _sleep();
  });
}

/** 生成随机组件Id */
export function createModelId(len = 36) {
  const s: Array<string> = [];
  const hexDigits = 'abcdefghijklmnopqrstuvwxyz';
  for (let i = 0; i < len; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 26), 1);
  }
  /** s[8] = s[13] = s[18] = s[23] = "-"; */
  const uuid = s.join('');
  return uuid;
}

/** 生成随机数字Id */
export function createNumberId(len = 36) {
  const s: Array<string> = [];
  const hexDigits = '0123456789';
  for (let i = 0; i < len; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 10), 1);
  }
  /** s[8] = s[13] = s[18] = s[23] = '-'; */
  const uuid = s.join('');
  return uuid;
}

/**
 * 日期格式化
 * @param {Date} [date=new Date()] 日期
 * @param {string} [fmt='yyyy-MM-dd'] 格式化参数
 * @return {string} 格式化后的日期
 */
export function dateFormat(date: string | Date = new Date(), fmt = 'yyyy-MM-dd'): string {
  if (!date) return '';
  let dateInstance: Date;
  if (typeof date === 'string') {
    dateInstance = new Date(date);
  } else {
    dateInstance = date;
  }
  if (date === new Date('-')) return '';
  const o: Record<string, any> = {
    'M+': dateInstance.getMonth() + 1,
    'd+': dateInstance.getDate(),
    'H+': dateInstance.getHours(),
    'm+': dateInstance.getMinutes(),
    's+': dateInstance.getSeconds(),
    'q+': Math.floor((dateInstance.getMonth() + 3) / 3),
    S: dateInstance.getMilliseconds(),
  };
  let _fmt = fmt;
  if (/(y+)/.test(fmt)) _fmt = _fmt.replace(RegExp.$1, `${dateInstance.getFullYear()}`.substr(4 - RegExp.$1.length));
  Object.keys(o).forEach(k => {
    if (new RegExp(`(${k})`).test(_fmt)) {
      _fmt = _fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length));
    }
  });
  return _fmt;
}

/** 交互数组中两段数值的位置 */
export function arrChange(arr: Array<any>, num1: number, num2: number, num1length = 1, num2length = 1) {
  let _num1 = num1;
  let _num2 = num2;
  let _num1length = num1length;
  let _num2length = num2length;
  if (num1 > num2) [_num1, _num2, _num1length, _num2length] = [num2, num1, num2length, num1length];

  return arr
    .slice(0, num1)
    .concat(arr.slice(_num2, _num2 + _num2length))
    .concat(arr.slice(_num1 + _num1length, _num2))
    .concat(arr.slice(_num1, _num1 + _num1length))
    .concat(arr.slice(_num2 + _num2length));
}

/** 移动组件树中某一项的位置 */
export function moveNodeOfTree(tree: Array<any>, id: string | number, pid: string | number, prop = 'id') {
  const _tree = cloneLoop(tree);
  let _node: any = null;
  let _orderIndex: number | undefined;

  let _isOver = false;
  const _cb = (parentNode: any, fn: any) => {
    if (parentNode?.children?.length) {
      for (let index = 0; index < parentNode.children.length; index++) {
        if (_isOver) _cb(parentNode.children[index], fn);
        const _re = fn(parentNode, index);
        if (_re) return;
      }
    }
  };

  _cb({ children: _tree, id: '' }, (parentNode: any, index: any): boolean => {
    if (!_isOver) {
      if (parentNode.id === '') {
        if (parentNode.children[index].id === id) {
          _orderIndex = undefined;
          _isOver = true;
          return true;
        }
      } else {
        for (let i = 0; i < parentNode.children[index].length; i++) {
          if (parentNode.children[index][i].id === id) {
            _orderIndex = i;
            _isOver = true;
            return true;
          }
        }
      }
    }
    return false;
  });

  _isOver = false;
  _cb({ children: _tree, id: '' }, (parentNode: any, index: any): boolean => {
    if (!_isOver) {
      if (_orderIndex !== undefined) {
        if (parentNode.children[index][_orderIndex]?.[prop] === id) {
          _node = parentNode.children[index][_orderIndex];
          parentNode.children[index].splice(_orderIndex, 1);
          _isOver = true;
          return true;
        }
      } else if (parentNode.children[index][prop] === id) {
        _node = parentNode.children[index];
        parentNode.children.splice(index, 1);
        _isOver = true;
        return true;
      }
    }
    return false;
  });

  if (!pid) {
    _tree.push(_node);
    return _tree;
  }
  _isOver = false;
  _cb({ children: _tree, id: '' }, (parentNode: any): boolean => {
    if (!_isOver) {
      if (parentNode.id === '') {
        const _insertIndex = pid ? parentNode.children.findIndex((i: any) => i.id === pid) : parentNode.children.length;
        parentNode.children.splice(_insertIndex, 0, _node);
        _isOver = true;
        return true;
      }
    }
    return false;
  });

  return _tree;
}

/** 数组转树 */
export function transformTree(
  data: Record<string, any>[],
  { idKey = 'id', parentKey = 'parentId', childrenKey = 'children', defaultKey = 0 } = {
    idKey: 'id',
    parentKey: 'parentId',
    childrenKey: 'children',
    defaultKey: 0,
  },
) {
  const childlist = {};
  data.forEach(item => {
    const parentId = item[parentKey] || defaultKey;
    if (childlist[parentId]) {
      childlist[parentId].push(item);
    } else {
      childlist[parentId] = [item];
    }
  });

  const basicTree = childlist[defaultKey];
  data.forEach(item => {
    // eslint-disable-next-line no-param-reassign
    item[childrenKey] = childlist[item[idKey]] || [];

    /** 比如一个二级菜单 目录是 医院管理 ，其下有着医生列表页面，
     *  某个角色只需要医生列表页面，不需要医院管理这个目录
     *  那么数组转树的时候那追寻不到父页面，那么需要添加
     *  用于场景：一个页面多个角色复用
     */
    if (item[parentKey] !== 0 && item.component && !data.filter(pageItem => pageItem[idKey] === item[parentKey]).length) {
      basicTree[0][childrenKey].push(item);
    }
  });

  if (childlist[defaultKey]) {
    return basicTree || {};
  } else {
    return childlist?.[defaultKey]?.[childrenKey] || {};
  }
}

/** 获取LocalStorage的值 */
export function getLocalstorge(item: string): string | null {
  return window.localStorage.getItem(item) || null;
}

/** Base64转Buffer */
export function base64ToBuffer(b: string): Uint8Array {
  const str = atob(b);
  const buffer = new Uint8Array(str.length);
  for (let i = 0; i < str.length; i++) {
    buffer[i] = str.charCodeAt(i);
  }
  return buffer;
}

/** 获取默认的分页参数 */
export function getPagination(config: Record<string, any> = {}): Record<string, any> {
  return {
    pageSizeOptions: ['10', '20', '40'],
    showQuickJumper: true,
    showSizeChanger: true,
    defaultCurrent: 1,
    current: 1,
    defaultPageSize: 10,
    ...config,
  };
}

/** 将数字插入千分位符 */
export function thousandNum(money: number | string): string {
  if (!Number.isNaN(Number(money))) {
    return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  throw new TypeError(`添加千分位符失败，当前参数为：${money}`);
}

/** 筛选对象的属性 */
export function filterObj(obj: Record<string, any>, outKeys: string[] = [], inKeys: string[] = []): Record<string, any> {
  let keys = Object.keys(obj);
  if (outKeys.length) {
    keys = keys.filter(i => !outKeys.includes(i));
  }
  if (inKeys.length) {
    keys = keys.filter(i => inKeys.includes(i));
  }
  return Object.assign({}, ...keys.map(i => ({ [i]: obj[i] })));
}

/** 获取Url参数 */
export function getParams() {
  return new URLSearchParams(window.location.search.replace(/\?/gi, ''));
}

function fakeClick(obj: any) {
  const ev = document.createEvent('MouseEvents');
  ev.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  obj.dispatchEvent(ev);
}

/** 下载文件 */
export function downLoadFile(name: string, data: string | Blob) {
  const urlObject = window.URL || window.webkitURL || window;
  let exportBlob;
  if (typeof data === 'string') {
    exportBlob = new Blob([data]);
  } else {
    exportBlob = data;
  }
  const saveLink = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
  saveLink.setAttribute('href', urlObject.createObjectURL(exportBlob));
  saveLink.setAttribute('download', name);
  fakeClick(saveLink);
}

export function downLoadFileByUrl(url: string, filename?: string) {
  const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
  save_link.setAttribute('target', '_blank');
  save_link.setAttribute('href', url);
  if (filename) save_link.setAttribute('download', filename);
  fakeClick(save_link);
}

/** 递归函数 */
export function recursive(
  formVariables: Array<Record<string, any>>,
  callback?: {
    filter?: (variable: Record<string, any>, chain: Array<Record<string, any>>) => boolean;
    map?: (variable: Record<string, any>, chain: Array<Record<string, any>>) => any;
  },
): Array<Record<string, any>> {
  if (!callback) {
    return formVariables;
  }

  const _list: Array<Record<string, any>> = [];

  /** 递归 */
  const _cb = (newParent: Record<string, any>, parent: Record<string, any>, chain: Array<Record<string, any>>) => {
    if (callback?.filter?.(newParent, chain) === false) {
      return;
    }
    const _item = {
      ...parent,
      children: [],
    };
    chain.push(_item);
    if (parent?.children?.length) {
      for (let i = 0; i < parent.children.length; i++) {
        _cb(_item, parent.children[i], chain);
      }
    }
    newParent.children.push(callback.map?.(_item, chain) || _item);
  };

  formVariables.forEach(item => {
    const _item = {
      ...item,
      children: [],
    };
    if (callback?.filter?.(_item, []) === false) {
      return;
    }

    if (item?.children?.length) {
      for (let i = 0; i < item.children.length; i++) {
        _cb(_item, item.children[i], [_item]);
      }
    }

    _list.push(callback.map?.(_item, [_item]) || _item);
  });

  return _list;
}

/** 回显数据字典 */
export function selectDictLabel(datas, value) {
  if (value === undefined) {
    return '';
  }
  const actions: string[] = [];
  Object.keys(datas).some(key => {
    if (datas[key].value === `${value}`) {
      actions.push(datas[key].label);
      return true;
    } else {
      return false;
    }
  });
  if (actions.length === 0) {
    actions.push(value);
  }
  if (Array.isArray(actions)) {
    return actions.join('');
  } else {
    return false;
  }
}

/**
 * 给对象数组每个元素添加 key label
 * @deprecated
 */
export function depDeptInfo(target: Record<string, any>[]) {
  if (target && target.length !== 0) {
    let _target = target;
    _target = target.map((item, index) => {
      _target[index].title = item.label || item.deptName || item.permissionName || item.menuName;
      _target[index].value = item.label || item.deptName || item.permissionName || item.menuName;
      depDeptInfo(item.children);
      return item;
    });
  }
}

/** 循环遍历树并替换 */
export function DeepTransArray(target, callback, childrenKey = 'children') {
  return target.map((item, index) => {
    return {
      ...callback(item, index),
      [childrenKey]: item[childrenKey] ? DeepTransArray(item[childrenKey], callback, childrenKey) : '',
    };
  });
}

/**
 * 后端返回的树形结构数据进入转换为前端适用的树形数据
 * @deprecated
 */
export function handleDataTree(target: Record<string, any>[]) {
  const _target = target;
  Object.entries(_target).forEach(([key, value]) => {
    const { children } = _target[key];
    _target[key] = { ..._target[key].data };
    if (children?.length) _target[key].children = children;
    if (_target[key].children?.length) {
      handleDataTree(_target[key].children);
    }
  });
  return _target;
}

/**
 * 数据添加 key 属性
 * @deprecated
 */
export function targetAddKey(target) {
  return target.map((item, index) => ({
    ...item,
    key: index,
  }));
}

/**
 * 转换字符串，undefined,null等转化为""
 * @deprecated
 */
export function parseStrEmpty(str) {
  if (!str || str === 'undefined' || str === 'null') {
    return '';
  }
  return str;
}

/** 数据合并 */
export function mergeRecursive(source: Record<string, any>, target: Record<string, any>) {
  const _result = source;
  Object.entries(target).forEach(([key, value]) => {
    try {
      if (target[key].constructor === Object) {
        _result[key] = mergeRecursive(source[key], target[key]);
      } else {
        _result[key] = target[key];
      }
    } catch (e) {
      _result[key] = target[key];
    }
  });
  return _result;
}

/**
 * 构造树型结构数据
 * @deprecated
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function handleTree(data: Record<string, any>, id = 'id', parentId = 'parentId', children = 'children') {
  const config = {
    id,
    parentField: parentId,
    childrenField: children,
  };

  const childrenListMap = {} as Record<string, any>;
  const nodeIds = {};
  const tree = [] as Record<string, any>[];

  function adaptToChildrenList(target: Record<string, any>) {
    const _target = target;
    if (childrenListMap[_target[config.id]] !== null) {
      _target[config.childrenField] = childrenListMap[_target[config.id]];
    }
    if (_target[config.childrenField]) {
      Object.entries(_target[config.childrenField]).forEach(([key, value]) => {
        adaptToChildrenList(value as Record<string, any>);
      });
    }
  }

  Object.entries(data).forEach(([key, value]) => {
    const _parentId = value[config.parentField];
    if (childrenListMap[_parentId] == null) {
      childrenListMap[_parentId] = [];
    }
    nodeIds[value[config.id]] = value;
    childrenListMap[_parentId].push(value);
  });

  Object.entries(data).forEach(([key, value]) => {
    const _parentId = value[config.parentField];
    if (nodeIds[_parentId] == null) {
      tree.push(value);
    }
  });

  Object.entries(tree).forEach(([key, value]) => {
    adaptToChildrenList(value);
  });

  return tree;
}

export function getFileType(filetype: string) {
  if (['application/pdf'].includes(filetype)) {
    return 'pdf';
  } else if (['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(filetype)) {
    return 'word';
  } else if (filetype.indexOf('image') > -1) {
    return 'image';
  } else {
    return '';
  }
}

export function getFileTypeByUrl(url: string) {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(response => {
        const contentType = response.headers.get('content-type');
        if (contentType) {
          const fileType = getFileType(contentType);
          resolve(fileType);
        } else {
          reject('获取文件失败:');
        }
      })
      .catch(error => {
        reject('获取文件失败:');
      });
  });
}

export default {
  dateFormat,
  getLocalstorge,
  getPagination,
  thousandNum,
  getParams,
  downLoadFile,
  recursive,
  filterObj,
  judgeStringIsJson,
  getFileTypeByUrl,
};
