
import { useProduct } from '@/modules/version-manage';
import { get, post } from '@/lib/api';
import { defineComponent, reactive, toRefs, ref, watch } from 'vue';

export default defineComponent({
  name: 'UserEdit',
  components: {},
  props: {
    /** 是否显示 */
    visible: {
      type: Boolean,
      default: false,
    },
    /** 用户Id */
    userId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const global = useProduct();

    const formRef = ref();

    const state = reactive({
      /** 已选择来源 */
      selectedSourceList: [] as Record<string, any>[],
      /** 来源列表 */
      roleList: [] as Record<string, any>[],
      /** 是否加载中 */
      isLoading: false,
      /** 作者列表 */
      authorList: [] as string[],
      /** 表单 */
      form: {
        name: '',
        nickName: '',
        phone: '',
        sex: '',
        birthday: '',
        roleKey: '',
      },
    });

    watch(
      () => props.visible,
      count => {
        if (count) {
          if (props.userId !== -1) {
            state.isLoading = true;
            get('/user/getUserById', {
              userId: props.userId,
            })
              .then(({ data }) => {
                state.form = data;
              })
              .finally(() => {
                state.isLoading = false;
              });
          }
        }
      },
    );

    const rules = {
      name: [{ required: true, message: '请输入用户名' }],
      nickName: [{ required: true, message: '请输入姓名' }],
    };

    return {
      ...toRefs(state),
      rules,
      global,
      formRef,
    };
  },
  created() {},
  mounted() {},
  methods: {
    onClose() {
      this.$emit('update:visible', false);
    },
    onSubmit() {
      this.isLoading = true;
      this.formRef
        .validate()
        .then(() => {
          post('/user/editUser', {
            ...this.form,
            id: this.userId,
          })
            .then(d => {
              this.$emit('update:visible', false);
              this.$emit('submit', d);
            })
            .finally(() => {
              this.isLoading = false;
            });
        })
        .catch(error => {
          this.isLoading = false;
          console.error('error', error);
        });
    },
  },
});
