
import { defineComponent, reactive, toRefs, onMounted, PropType, watch, inject, computed } from 'vue';
import { get } from '@/lib/api';
import { dateFormat, getDataSource } from '@/tools/common';
import { message } from 'ant-design-vue';
import bus from '@/tools/bus';
import { ComponentTable } from '@/@types/version-manage/component/component-table';
import { OperateConfig } from '@/@types/version-manage/page-config';
import { useProduct } from '@/modules/version-manage';
import { storeToRefs } from 'pinia';

export default defineComponent({
  components: {},
  props: {
    /** 数据 */
    data: {
      type: Object as PropType<ComponentTable>,
      default: () => ({}),
    },
  },
  setup(props) {
    const formState = reactive({
      /** 分页参数 */
      pagination:
        typeof props.data.pagination === 'boolean' && props.data.pagination === false
          ? undefined
          : {
              current: 1,
              pageSize: typeof props.data.pagination !== 'boolean' ? props.data.pagination?.pageSize || 10 : 10,
              total: 0,
            },
      /** 筛选项配置 */
      filterConfig: (inject('filterConfig') ?? {}) as Record<string, any>,
      /** 表格数据 */
      dataSource: [] as any[],
      /** 编辑期刊信息Id */
      editUserId: 0,
      /** 表格列 */
      columns: props.data.columns
        .map(i => ({
          title: i.label,
          width: i.width,
          key: i.name,
          dataIndex: i.name,
        }))
        .concat([
          {
            title: '操作',
            width: 150,
            key: 'action',
            dataIndex: 'action',
          },
        ]),
    });

    const isLoading = computed(() => {
      return (inject('getIsLoading') as () => boolean)();
    });

    const _getPageFilter = inject('getPageFilter') as () => Record<string, any>;
    /** 获取页面筛选项 */
    const getPageFilter = computed(() => {
      return _getPageFilter();
    });

    const setIsLoading = inject('setIsLoading') as (boolean) => void;

    /** 查询 */
    const search = (isSearch: boolean = false) => {
      return new Promise((resolve, reject) => {
        if (isSearch && formState.pagination) {
          formState.pagination.current = 1;
        }
        const hide = message.loading('加载中...', 0);
        setIsLoading(true);

        getDataSource<any[]>(props.data.dataSource, {
          pagination: formState.pagination,
          filter: getPageFilter.value,
        })
          .then(d => {
            formState.dataSource = d;
            resolve(d);
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            hide();
            setIsLoading(false);
          });
      });
    };

    bus.$on('pageSearch', (reload: boolean) => {
      search(reload);
    });

    const handleTableChange = (page: { pageSize: number; current: number }) => {
      if (formState.pagination) {
        formState.pagination.pageSize = page.pageSize;
        formState.pagination.current = page.current;
      }
      search();
    };

    /** 重设筛选项 */
    const reset = () => {
      bus.$emit('pageReset');
    };

    /** 监听data */
    watch(
      () => props.data,
      (data, prevData) => {
        if (data) {
          formState.pagination = {
            current: 1,
            pageSize: 10,
            total: 0,
          };
          formState.columns = props.data.columns.map(i => ({
            title: i.label,
            width: i.width,
            key: i.name,
            dataIndex: i.name,
          }));
          search();
        }
      },
    );

    /** 配置重设 */
    const configReset = () => {};

    onMounted(() => {
      search();
    });

    return {
      ...toRefs(formState),
      global: storeToRefs(useProduct()),
      dateFormat,
      handleTableChange,
      search,
      isLoading,
      reset,
    };
  },
  created() {},
  mounted() {},
  methods: {
    /** 显示编辑框 */
    showDrawer(info: OperateConfig, record: Record<string, any>) {
      bus.$emit('showComponentDialog', {
        keyId: record.id,
        code: info.name,
      });
    },
    /** 删除数据 */
    removeItem(record) {
      getDataSource(record);
      get('/user/deleteUser', {
        userId: record.id,
      }).then(d => {
        message.success(`已删除数据${record.name}`);
      });
    },
  },
});
