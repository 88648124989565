import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ComponentFlexItem = _resolveComponent("ComponentFlexItem")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({
      display: 'flex',
      flexDirection: _ctx.data.direction,
      justifyContent: _ctx.data.mainAxisAlignment,
    })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.content, (item, index) => {
      return (_openBlock(), _createBlock(_component_ComponentFlexItem, {
        key: index,
        data: item
      }, null, 8, ["data"]))
    }), 128))
  ], 4))
}