
import bus from '@/tools/bus';
import { message } from 'ant-design-vue';
// import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'ObjectEditor',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    marker: {
      type: Function,
    },
    height: {
      type: String,
      default: '20px',
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    /** 是否为表单表达式 */
    isExpression: {
      type: Boolean,
      default: false,
    },
    unseenLines: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: '请输入JSON字符串',
    },
    /** 表单变量 */
    variables: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const editorDom = ref();

    return {
      editorDom,
    };
  },
  data: () => ({
    editor: null as any,
    content: '',
    defaultOptions: {
      wordWrap: 'off', // 控制如何换行
      automaticLayout: true,
      fontFamily: "'Fira Code', Consolas, 'Courier New', monospace, 'Microsoft YaHei'",
    },
    /** 停止绘制 */
    preventTriggerChangeEvent: false,
  }),
  watch: {
    value(newVal) {
      this.handerCodeChange(newVal);
    },
  },
  created() {
    window.monaco.editor.defineTheme('gj-dark', {
      base: 'vs-dark',
      inherit: true,
      rules: [{ token: 'custom-variable', foreground: 'ffa500', fontStyle: 'underline' }],
      colors: {},
    });

    // validation settings
    window.monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      noSemanticValidation: true,
      noSyntaxValidation: false,
    });

    // compiler options
    window.monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      target: window.monaco.languages.typescript.ScriptTarget.ESNext,
      allowNonTsExtensions: true,
    });

    // 引入变量
    const _variables = this.variables.map(
      (i: any) => `
                ${i.remark ? `/** ${i.remark} */\n` : ''}${i.keyword} ${i.name}: ${i.type};
            `,
    );
    window.monaco.languages.typescript.javascriptDefaults.addExtraLib(_variables.join('\n'), 'vars.d.ts');
  },
  mounted() {
    this.initialize();
  },
  beforeUnmount() {
    this.destroy();
  },
  methods: {
    initialize() {
      // onDidChangeOptions 配置改变事件
      // onDidChangeLanguage 语言改变事件

      const _options = {
        ...this.defaultOptions,
        ...this.options,
        theme: 'gj-dark',
        language: 'javascript',
      };

      this.editor = window.monaco.editor.create(this.editorDom, {
        value: JSON.stringify(this.value, undefined, '    '),
        ..._options,
      } as any);

      this.editor.onDidChangeModelContent(() => {
        if (!this.preventTriggerChangeEvent) {
          // this.$emit('input', JSON.parse(this.getValue()));
        }
      });

      this.$emit('ready', this.editor);

      this.refresh();

      this.$nextTick(() => {
        this.editor.onDidBlurEditorText(() => {
          if (!this.preventTriggerChangeEvent) {
            try {
              const _val = this.getValue();
              if (_val) {
                // eslint-disable-next-line no-new-func
                this.$emit('input', Function(`return ${_val}`)());
              }
            } catch (err: any) {
              message.error(err.message);
            }
          }
        });
      });

      bus.$on('prop_change', () => {
        try {
          const _val = this.getValue();
          if (_val) {
            // eslint-disable-next-line no-new-func
            this.$emit('input', Function(`return ${_val}`)());
          }
        } catch (e: any) {
          message.error(e.message);
        }
      });
    },
    refresh() {
      this.editor.layout();
      // this.editor.remeasureFonts();
    },
    setValue(value) {
      this.editor.setValue(value);
    },
    getValue() {
      return this.editor.getValue();
    },
    handerCodeChange(value) {
      const model = this.editor.getModel();
      if (this.value && this.value !== this.editor.getValue()) {
        this.preventTriggerChangeEvent = true;
        this.editor.pushUndoStop();
        model.pushEditOperations(
          [],
          [
            {
              range: model.getFullModelRange(),
              text: value,
            },
          ],
        );
        this.editor.pushUndoStop();
        this.refresh();
        this.preventTriggerChangeEvent = false;
      }
    },
    insertContent(text) {
      if (this.editor) {
        const selection = this.editor.getSelection();
        const range = new window.monaco.Range(selection.startLineNumber, selection.startColumn, selection.endLineNumber, selection.endColumn);
        const id = { major: 1, minor: 1 };
        const op = { identifier: id, range, text, forceMoveMarkers: true };
        this.editor.executeEdits(this.$root, [op]);
        this.editor.focus();
      }
    },
    destroy() {
      this.editor.dispose();
      const model = this.editor.getModel();
      if (model) {
        model.dispose();
      }
    },
  },
});
