
import Vue, { toRefs, computed, defineComponent, inject, PropType, reactive, ref } from 'vue';

interface Rules {
  vue?: typeof Vue;
  /** 是否启用校验 */
  enable: boolean;
  /** 值 */
  value: any;
  /** 是否显示 */
  show?: boolean;
  /** 提示信息 */
  message(): string;
}

export default defineComponent({
  name: 'RulesEditor',
  props: {
    /** 当前变量 */
    value: {
      type: Array as PropType<Array<any>>,
      default: () => [],
      required: true,
    },
    /** 筛选变量类型 */
    type: {
      type: String,
      default: 'any',
      required: true,
    },
    size: {
      type: String as PropType<'large' | 'default' | 'small'>,
      default: 'default',
      required: true,
    },
  },
  setup() {
    const state = reactive({
      /** 真实值 */
      inputValue: [] as string[],
      /** 所有条件状态 */
      allRules: {} as Record<string, Rules>,
      /** 内建校验类型选项 */
      types: [
        { value: 'any', label: '任意值' },
        { value: 'string', label: '字符串' },
        { value: 'number', label: '数字' },
        { value: 'boolean', label: '布尔/真假' },
        { value: 'method', label: '函数' },
        { value: 'regexp', label: '正则表达式' },
        { value: 'integer', label: '整数' },
        { value: 'float', label: '浮点数' },
        { value: 'array', label: '数组' },
        { value: 'object', label: '对象' },
        { value: 'enum', label: '枚举' },
        { value: 'date', label: '日期' },
        { value: 'url', label: '地址' },
        { value: 'hex', label: 'HEX颜色' },
        { value: 'email', label: '邮箱' },
      ],
    });

    return {
      ...toRefs(state),
    };
  },
  watch: {
    type() {
      this.changeType();
    },
  },
  mounted() {
    this.init();
  },
  created() {
    this.allRules = {
      required: { show: false, enable: true, value: undefined, message: () => '{{label}}不能为空。' },
      enum: { show: false, enable: false, value: undefined, message: () => '{{label}}必须为指定值。' },
      len: { show: false, enable: false, value: undefined, message: () => '{{label}}长度必须为{{value}}位。' },
      min: {
        show: false,
        enable: false,
        value: undefined,
        message: () => `{{label}}最小不能低于{{value}}${this.type === 'text' ? '位' : ''}。`,
      },
      max: {
        show: false,
        enable: false,
        value: undefined,
        message: () => `{{label}}最大不能超过{{value}}${this.type === 'text' ? '位' : ''}。`,
      },
      pattern: { show: false, enable: false, value: undefined, message: () => '{{label}}格式不正确。' },
      type: { show: false, enable: false, value: undefined, message: () => '{{label}}必须为{{value}}格式。' },
      validator: { show: false, enable: false, value: undefined, message: () => '{{label}}格式不正确。' },
      whitespace: { show: false, enable: true, value: undefined, message: () => '' },
    };
  },
  methods: {
    /** 初始化 */
    init() {
      if (this.value) {
        this.value.forEach(i => {
          this.allRules[i.category] = {
            ...this.allRules[i.category],
            enable: !!i[i.category],
            value: i[i.category],
          };
        });
      }
      this.changeType();
    },
    changeType() {
      let _rules: string[] = [];
      switch (this.type) {
        case 'text':
          _rules = ['required', 'enum', 'len', 'min', 'max', 'pattern', 'validator', 'whitespace']; // 'type',
          break;
        case 'number':
          _rules = ['required', 'min', 'max', 'validator', 'whitespace'];
          break;
        case 'select':
          _rules = ['required'];
          break;
        case 'date':
          _rules = ['required'];
          break;
        case 'upload':
          _rules = ['required'];
          break;
        default:
          break;
      }
      Object.entries(this.allRules).forEach(([key, value]) => {
        this.allRules[key].show = _rules.includes(key);
      });
    },
    /** 改变值 */
    change() {
      const re = Object.entries(this.allRules)
        .filter(([key, value]) => value.enable && !!value.value)
        .map(([key, value]) => {
          return { [key]: value.value, category: key, message: value.message() };
        });
      this.$emit('input', re);
    },
    changeEnable(variable?) {
      if (variable) {
        // eslint-disable-next-line no-param-reassign
        variable.value = undefined;
      }
      this.change();
    },
  },
});
