import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, createSlots as _createSlots, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-10128b8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "model-list-editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlusOutlined = _resolveComponent("PlusOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_DeleteOutlined = _resolveComponent("DeleteOutlined")!
  const _component_ToolOutlined = _resolveComponent("ToolOutlined")!
  const _component_ArrowUpOutlined = _resolveComponent("ArrowUpOutlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_ArrowDownOutlined = _resolveComponent("ArrowDownOutlined")!
  const _component_a_menu_divider = _resolveComponent("a-menu-divider")!
  const _component_CopyOutlined = _resolveComponent("CopyOutlined")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_table, {
      "row-key": _ctx.rowKey,
      pagination: false,
      size: "small",
      columns: _ctx.tableColumns(),
      scroll: _ctx.scroll,
      "data-source": _ctx.value
    }, _createSlots({
      slot_operation: _withCtx(({ index }) => [
        _createVNode(_component_a_button, {
          type: "primary",
          size: "small",
          onClick: ($event: any) => (_ctx.addRow(index))
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_PlusOutlined)
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_a_button, {
          type: "primary",
          size: "small",
          danger: "",
          style: {"margin-left":"6px"},
          onClick: ($event: any) => (_ctx.removeRow(index))
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_DeleteOutlined)
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_a_dropdown, { trigger: ['click'] }, {
          overlay: _withCtx(() => [
            _createVNode(_component_a_menu, null, {
              default: _withCtx(() => [
                _createVNode(_component_a_menu_item, {
                  key: "1",
                  disabled: index == 0,
                  onClick: ($event: any) => (_ctx.moveUp(index))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ArrowUpOutlined),
                    _createTextVNode("上移")
                  ]),
                  _: 2
                }, 1032, ["disabled", "onClick"]),
                _createVNode(_component_a_menu_item, {
                  key: "2",
                  disabled: index == _ctx.value.length - 1,
                  onClick: ($event: any) => (_ctx.moveDown(index))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ArrowDownOutlined),
                    _createTextVNode("下移")
                  ]),
                  _: 2
                }, 1032, ["disabled", "onClick"]),
                _createVNode(_component_a_menu_divider),
                _createVNode(_component_a_menu_item, {
                  key: "2",
                  onClick: ($event: any) => (_ctx.copyRow(index))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CopyOutlined),
                    _createTextVNode("复制")
                  ]),
                  _: 2
                }, 1032, ["onClick"])
              ]),
              _: 2
            }, 1024)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_button, {
              size: "small",
              style: {"margin-left":"6px"}
            }, {
              icon: _withCtx(() => [
                _createVNode(_component_ToolOutlined)
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1024)
      ]),
      _: 2
    }, [
      _renderList(_ctx.columns, (col, colIndex) => {
        return {
          name: `slot_${col.name}`,
          fn: _withCtx(({ record, index }) => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.global.propertyEditors[col.editor].component), _mergeProps(Object.assign({}, record.attrs || {}, _ctx.component.attrs || {}, col.attrs || {}), {
              ref: `${col.name}${colIndex}${_ctx.component.name}${index}`,
              value: _ctx.value[index][col.name],
              onChange: ($event: any) => (_ctx.changeValue($event, index, col.name))
            }), _createSlots({ _: 2 }, [
              _renderList(Object.keys(_ctx.global.propertyEditors[col.editor].slot), (slot) => {
                return {
                  name: slot,
                  fn: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.global.propertyEditors[col.editor].slot[slot], (detailComponent, index3) => {
                      return (_openBlock(), _createBlock(_resolveDynamicComponent(detailComponent.component), _mergeProps(detailComponent.attrs, {
                        key: slot + detailComponent.component + index3
                      }), _createSlots({
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(detailComponent.html) + " ", 1)
                        ]),
                        _: 2
                      }, [
                        _renderList(Object.keys(detailComponent.slot), (detailSlot) => {
                          return {
                            name: detailSlot,
                            fn: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(detailComponent.slot[detailSlot], (detail2Component, index4) => {
                                return (_openBlock(), _createBlock(_resolveDynamicComponent(detail2Component.component), _mergeProps(detail2Component.attrs, {
                                  key: detailSlot + detail2Component.component + index4
                                }), {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(detail2Component.html), 1)
                                  ]),
                                  _: 2
                                }, 1040))
                              }), 128))
                            ])
                          }
                        })
                      ]), 1040))
                    }), 128))
                  ])
                }
              })
            ]), 1040, ["value", "onChange"]))
          ])
        }
      })
    ]), 1032, ["row-key", "columns", "scroll", "data-source"])
  ]))
}