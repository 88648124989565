import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c34ad37"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_input = _resolveComponent("a-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_input, {
      value: _ctx.inputValue,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      placeholder: _ctx.placeholder,
      onChange: _ctx.change,
      onInput: _ctx.input
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "suffix", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["value", "placeholder", "onChange", "onInput"])
  ]))
}