
import { defineComponent, PropType } from 'vue';
import { ComponentPropertyEditor } from '@/@types/version-manage/enum';
import { message } from 'ant-design-vue';
import { FormItem } from '@/@types/version-manage/form';
import { storeToRefs } from 'pinia';
import { useProduct } from '@/modules/version-manage';

export default defineComponent({
  name: 'ModelListEditor',
  components: {},
  props: {
    /** 当前值 */
    value: {
      type: Array as PropType<any[]>,
      default: () => [],
      required: true,
    },
    /** 删除判断函数 */
    onRemove: {
      type: Function,
    },
    /** splice函数 */
    onChange: {
      type: Function,
      required: true,
    },
    /** 主列名 */
    rowKey: {
      type: String,
      default: 'value',
    },
    /** 绑定组件 */
    component: {
      type: Object as PropType<FormItem>,
      required: true,
    },
    /** 表格滚动条配置 */
    scroll: {
      type: Object as PropType<any>,
    },
    /** 配置列 */
    columns: {
      type: Object as PropType<
        {
          name: string;
          title: string;
          fixed?: 'left' | 'right';
          width?: string;
          attrs: Record<string, any>;
          editor: ComponentPropertyEditor;
        }[]
      >,
      required: true,
    },
  },
  setup() {
    return {
      global: storeToRefs(useProduct()),
    };
  },
  mounted() {},
  methods: {
    /** 新增行 */
    addRow(index) {
      this.$emit('update:value', [...this.value.slice(0, index), {}, ...this.value.slice(index)]);
      this.onChange?.(this.component, index, 0, 1);
    },
    /** 复制行 */
    copyRow(index) {
      const re = [...this.value.slice(0, index), { ...this.value }, ...this.value.slice(index)];
      this.$emit('update:value', re);
      this.$emit('change', re);
      this.onChange?.(this.component, index, 0, 1);
    },
    /** 删除行 */
    removeRow(index) {
      const _re = this.onRemove?.(this.value, index, this.component);
      if (_re !== false) {
        const re = [...this.value.slice(0, index), ...this.value.slice(index + 1)];
        this.$emit('update:value', re);
        this.$emit('change', re);
        this.onChange?.(this.component, index, 1, 0);
      } else {
        message.warning('无法删除，请确认后重试');
      }
    },
    /** 上移行 */
    moveUp(index) {
      const re = [...this.value.slice(0, index - 1), this.value[index], this.value[index - 1], ...this.value.slice(index + 1)];
      this.$emit('update:value', re);
      this.$emit('change', re);
      this.onChange?.(this.component, index);
    },
    /** 下移行 */
    moveDown(index) {
      const re = [...this.value.slice(0, index), this.value[index + 1], this.value[index], ...this.value.slice(index + 2)];
      this.$emit('update:value', re);
      this.$emit('change', re);
      this.onChange?.(this.component, index);
    },
    tableColumns() {
      return (this.columns || [])
        .map(i => ({
          title: i.title,
          key: i.name,
          dataIndex: i.name,
          width: i.width,
          fixed: i.fixed,
          slots: { customRender: `slot_${i.name}` },
        }))
        .concat([
          {
            title: '编辑',
            key: 'operation',
            dataIndex: 'operation',
            width: '100px',
            fixed: undefined,
            slots: { customRender: 'slot_operation' },
          },
        ]);
    },
    /** 改变值 */
    changeValue(val: any, index: number, name: string) {
      const _newVal = [...this.value];
      if (val.target) {
        _newVal[index][name] = val.target.value;
      } else {
        _newVal[index][name] = val;
      }
      this.$emit('update:value', _newVal);
      this.$emit('change', _newVal);
    },
  },
});
