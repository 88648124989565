
import { getProductDetail, productEdit, addProduct } from '@/api/version-manage';
import { useProduct } from '@/modules/version-manage';
import { upload } from '@/tools/api';
import { getBase64 } from '@/tools/common';
import { message, UploadChangeParam } from 'ant-design-vue';
import { defineComponent, reactive, toRefs, ref, watch, PropType } from 'vue';

export default defineComponent({
  components: {},
  props: {
    /** 是否显示 */
    visible: {
      type: Boolean,
      default: false,
    },
    /** 产品Id */
    productId: {
      type: Number as PropType<number | undefined>,
    },
    /** 是否禁用 */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const global = useProduct();

    const formRef = ref();

    const state = reactive({
      /** 已选择来源 */
      selectedSourceList: [] as Record<string, any>[],
      /** 来源列表 */
      sourceList: [] as Record<string, any>[],
      /** 是否加载中 */
      isLoading: false,
      /** 作者列表 */
      authorList: [] as string[],
      /** 上传中 */
      isUploadLoading: false,
      /** 上传列表 */
      uploadList: [] as any[],
      /** 表单 */
      form: {
        productName: '',
        description: '',
        productOwner: '',
        active: true,
      },
      /** 单独弄一个图标 图标不用加在表单里面 */
      icon: '',
      /** 表单规则 */
      rules: {
        productName: [{ required: true, message: '请输入产品名称' }],
      },
    });

    /** 清空数据 */
    const clear = () => {
      state.form = {
        productName: '',
        description: '',
        productOwner: '',
        active: true,
      };
      state.uploadList = [];
    };

    const handleChange = (info: UploadChangeParam) => {
      if (info.file.status === 'uploading') {
        state.isUploadLoading = true;
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (base64Url: string) => {
          state.icon = base64Url;
          state.isUploadLoading = false;
        });
      }
      if (info.file.status === 'error') {
        state.isUploadLoading = false;
        message.error('upload error');
      }
    };

    /** 上传文件 */
    const uploadFile = (productId?: number): Promise<void> => {
      return new Promise((resolve, reject) => {
        if (state.uploadList.length && state.uploadList[0].uid !== 'init-logo') {
          const hide = message.loading('上传中', 0);
          state.isUploadLoading = true;
          upload('/system/program/ver/version/logo/upload', {
            file: state.uploadList.pop().originFileObj,
            id: productId ?? props.productId,
            type: 1,
          })
            .then(d => {
              resolve();
            })
            .catch(err => {
              reject(err);
            })
            .finally(() => {
              hide();
              state.isUploadLoading = false;
            });
        } else {
          resolve();
        }
      });
    };

    const beforeUpload = file => {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('图标文件最大为2mb。');
      }
      if (isLt2M) {
        state.uploadList = [...state.uploadList, file];
      }
      return false;
    };

    watch(
      () => props.visible,
      count => {
        if (count) {
          if (props.productId) {
            state.isLoading = true;
            getProductDetail({
              id: props.productId,
            })
              .then(data => {
                clear();
                if (data) {
                  state.form = {
                    productName: data.productName,
                    description: data.description,
                    productOwner: data.productManager,
                    active: data.enable,
                  };
                  if (data.icon) {
                    const signRepeat = state.uploadList.find(item => item.url === data.icon);
                    if (!signRepeat) {
                      state.uploadList.push({
                        name: data.icon.substr(data.icon.lastIndexOf('/') + 1),
                        status: 'done',
                        thumbUrl: data.icon,
                        url: data.icon,
                        uid: 'init-logo',
                      });
                    }
                  }
                }
              })
              .finally(() => {
                state.isLoading = false;
              });
          } else {
            clear();
          }
        }
      },
    );

    return {
      ...toRefs(state),
      global,
      formRef,
      clear,
      beforeUpload,
      handleChange,
      uploadFile,
      state,
    };
  },
  created() {},
  mounted() {},
  methods: {
    onClose() {
      this.clear();
      this.$emit('update:visible', false);
    },
    onSubmit() {
      this.isLoading = true;
      this.formRef
        .validate()
        .then(() => {
          const oldForm = this.form;
          const newForm = {
            productName: oldForm.productName,
            description: oldForm.description,
            productManager: oldForm.productOwner,
            enable: oldForm.active,
          };
          if (this.productId) {
            Promise.all([
              productEdit({
                ...newForm,
                id: this.productId,
              }),
              this.uploadFile(),
            ])
              .then(d => {
                this.$emit('update:visible', false);
                this.$emit('submit', d);
              })
              .finally(() => {
                this.isLoading = false;
              });
          } else {
            addProduct({
              ...newForm,
            })
              .then(d => {
                if (this.uploadList.length) {
                  this.uploadFile(d.id).then(d1 => {
                    this.$emit('update:visible', false);
                    this.$emit('submit', d);
                  });
                } else {
                  this.$emit('update:visible', false);
                  this.$emit('submit', d);
                }
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        })
        .catch(error => {
          this.isLoading = false;
          console.error('error', error);
        });
    },
  },
});
