import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createBlock(_component_a_select, {
    ref: "editor",
    value: _ctx.inputValue,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
    valueModifiers: { sync: true },
    size: _ctx.size,
    options: _ctx.conditionList,
    class: "condition-picker",
    "show-search": false,
    "allow-clear": false,
    style: {"width":"100%"},
    onChange: _ctx.change
  }, null, 8, ["value", "size", "options", "onChange"]))
}