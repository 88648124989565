
import { getType, dateFormat } from '@/tools/common';
import { defineComponent, inject, PropType } from 'vue';

export default defineComponent({
  name: 'SimpleEditor',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      dateFormat,
    };
  },
  computed: {
    type() {
      return getType(this.value);
    },
  },
  methods: {
    /** 改变值 */
    change(e) {
      let val: any;
      switch (this.type) {
        case 'string':
          val = `${e.target.value}`;
          break;
        case 'array':
          val = e.target.value.split(',');
          break;
        case 'number':
          val = Number(e.target.value);
          break;
        case 'boolean':
          if (e.target.checked === 'true') val = true;
          else if (e.target.checked === 'false') val = false;
          else val = Boolean(e.target.checked);
          break;
        case 'date':
          val = new Date(e.target.value);
          break;
        default:
          break;
      }
      this.$emit('update:value', val);
      this.$emit('change', val);
    },
  },
});
