
import { defineComponent, reactive, toRefs, onMounted, PropType, computed, watch } from 'vue';
import { createNumberId, dateFormat } from '@/tools/common';
import { message } from 'ant-design-vue';
import G6 from '@antv/g6';
import { initCardNode } from '@/lib/version-manage/g6/card-node';
import { useProduct } from '@/modules/version-manage';
import { searchProjectVersionInfo } from '@/api/version-manage';

export default defineComponent({
  components: {},
  props: {
    /** 是否显示 */
    visible: {
      type: Boolean,
      default: false,
    },
    /** 项目Id */
    projectId: {
      type: Number as PropType<number | undefined>,
    },
  },
  setup(props) {
    const global = useProduct();

    const state = reactive({
      /** 随机数 */
      randomNum: createNumberId(10),
      /** 加载中 */
      isLoading: false,
      /** 节点数据 */
      nodes: [] as any[],
    });

    /** 节点Id */
    const elementId = computed(() => {
      return `version-chart-${state.randomNum}`;
    });

    /** 绘制图表 */
    const paint = () => {
      const minimap = new G6.Minimap({
        size: [150, 100],
      });
      const graph = new G6.Graph({
        container: elementId.value,
        width: 700,
        height: 600,
        defaultNode: {
          shape: 'card-node',
        },
        defaultEdge: {
          type: 'polyline',
          style: {
            radius: 20,
            offset: 43,
            endArrow: true,
            lineWidth: 2,
            stroke: '#C2C8D5',
          },
        },
        modes: {
          default: ['zoom-canvas', 'drag-canvas'],
        },
        layout: {
          type: 'dagre',
          nodesepFunc: d => {
            return 50;
          },
          ranksep: 50,
        },
        plugins: [minimap], // timebar,
      });

      initCardNode();

      graph.data({
        nodes: state.nodes,
        edges: [],
      });
      graph.render();
      // graph.fitCenter();
    };

    const search = () => {
      state.isLoading = true;
      const hide = message.loading('加载中...', 0);
      searchProjectVersionInfo({
        pageNum: 1,
        pageSize: 9999,
        environment: 'production',
        release: true,
        projectId: Number(props.projectId),
      })
        .then(d => {
          state.nodes = d.map(i => ({
            id: String(i.id),
            type: 'card-node',
            version: i.version,
            environment: i.environment,
            status: '已发布',
            // feature: i.branchInfo.branchName,
            createDate: i.createTime,
            date: i.createTime?.substring(0, 10).replace(/-/g, ''),
            value: new Date(i.createTime).getTime(),
          }));
          document.querySelector(`#${elementId.value}`)!.innerHTML = '';
          paint();
        })
        .finally(() => {
          state.isLoading = false;
          hide();
        });
    };

    /** 清空数据 */
    const clear = () => {};

    watch(
      () => props.visible,
      (count, prevCount) => {
        if (count) {
          if (props.projectId) {
            state.isLoading = true;
            search();
          } else {
            clear();
          }
        }
      },
    );

    onMounted(() => {});

    return {
      ...toRefs(state),
      global,
      dateFormat,
      search,
      paint,
      elementId,
      clear,
    };
  },
  created() {},
  mounted() {},
  methods: {
    onClose() {
      this.clear();
      this.$emit('update:visible', false);
    },
  },
});
