
import { defineComponent, PropType, inject } from 'vue';
import { FormItem } from '@/@types/version-manage/form';
import EditorItem from '@/components/version-manage/EditorItem.vue';
import { useProduct } from '@/modules/version-manage';

export default defineComponent({
  components: {
    EditorItem,
  },
  props: {
    /** 属性 */
    filterItems: {
      type: Object as PropType<FormItem[]>,
      required: true,
    },
  },
  setup() {
    const global = useProduct();

    const getPageFilter = inject('getPageFilter') as () => Record<string, any>;

    const setPageFilter = inject('setPageFilter') as (val) => void;

    return {
      global,
      getPageFilter,
      setPageFilter,
    };
  },
  created() {},
  mounted() {},
  methods: {
    /** 设置多个值 */
    changeMulValue([keys, vals]: any) {
      const _val = {};
      keys.forEach((item, index) => {
        _val[item] = vals[index];
      });
      this.setPageFilter(_val);
    },
    /** 属性修改触发的事件 */
    propChangeListener(val) {
      if (val.target) {
        console.warn('属性值包含val.target', val);
        return;
      }
      this.$emit('update:value', val);
      this.$emit('change', val);
    },
  },
});
