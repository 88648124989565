import { TabPageItem } from '@/@types';
import router from '@/config/router';
import { nextTick, reactive } from 'vue';
import { RouteLocationNormalized, RouteParams, RouteRecordRaw } from 'vue-router';
import { state as globalState } from '@/modules/global';
import NProgress from 'nprogress';

/** 页面Tab模块状态 */
export const state = reactive({
  /** 当前tab页路径 */
  activePage: '',
  /** Tabs页面集合 */
  pageTabs: [] as TabPageItem[],
  /** 当前tab页索引 */
  get activePageIndex() {
    // eslint-disable-next-line no-use-before-define
    return state.pageTabs.findIndex(service.isActive);
  },
});

/** SessionStorage保存TabPage数据的Key */
const STORAGE_TAB_PAGE_KEY = 'neuton_tab_pages';

/** 页面Tab模块逻辑 */
export const service = {
  /** 是否当前页面 */
  isActive(pane: TabPageItem) {
    return pane.name === state.activePage || pane.fullPath === state.activePage;
  },
  /** 初始化TabsInfo */
  initTabsInfo() {
    state.pageTabs = service.getTabsInfo();
  },
  /** 保存TabsInfo */
  saveTabsInfo() {
    sessionStorage.setItem(STORAGE_TAB_PAGE_KEY, JSON.stringify(state.pageTabs));
  },
  /** 登录登出时候都需要将面包屑清空 */
  clearCurmb() {
    state.pageTabs = [];
  },
  /** 动态切换医院之后返回首页 面包屑清空为首页 */
  curmbToHome() {
    state.pageTabs = [{ path: '/home', fullPath: '/home', name: 'Index', title: '主页', fixed: false, meta: {} }];
  },
  /** 从缓存获取TabsInfo */
  getTabsInfo() {
    return JSON.parse(sessionStorage.getItem(STORAGE_TAB_PAGE_KEY) || '[]');
  },
  /** 切换当前Tab项 */
  changeTab(index: number) {
    if (index < 0 || index > state.pageTabs.length) {
      debugger;
    }
    if (state.pageTabs[index].path === '/externa-link') {
      state.activePage = state.pageTabs[index].name as string;
      router.replace({
        path: '/externa-link',
        query: {
          src: state.pageTabs[index].name as string,
          title: state.pageTabs[index].title,
        },
      });
    } else {
      state.activePage = state.pageTabs[index].fullPath;
      router.replace(state.pageTabs[index].fullPath);
    }
  },
  /** 移除当前Tab项 */
  removeTab(index: number) {
    if (state.activePage === state.pageTabs[index].fullPath) {
      let _currentIndex = state.activePageIndex;
      if (state.pageTabs.length > _currentIndex && _currentIndex >= 0) {
        _currentIndex -= 1;
        if (state.pageTabs[_currentIndex].meta.link) {
          router.replace(state.pageTabs[_currentIndex].fullPath);
          state.activePage = state.pageTabs[_currentIndex].name as string;
        } else {
          router.replace(state.pageTabs[_currentIndex].fullPath);
          state.activePage = state.pageTabs[_currentIndex].fullPath;
        }
      } else {
        router.replace('/home');
      }
    }
    state.pageTabs.splice(index, 1);
    service.saveTabsInfo();
  },
  /** 根据路由创建新Tab页 */
  createNewTab(page: RouteLocationNormalized, params?: RouteParams, returnPage: boolean = false) {
    const _currentPageIndex = state.pageTabs.findIndex(i => i.path === page.fullPath && page.fullPath);
    if (_currentPageIndex >= 0) {
      const _page = state.pageTabs[_currentPageIndex];
      state.activePage = _page.fullPath as string;
      if (returnPage) {
        router.replace({
          ..._page,
          params,
        });
      }
    } else {
      state.activePage = page.fullPath as string;
      state.pageTabs.push({
        path: page.path,
        fullPath: page.fullPath,
        name: page.name,
        title: (page.meta?.['title'] || page?.query?.title || '') as string,
        fixed: false,
        params,
        meta: {},
      });
      if (returnPage) {
        router.replace({
          path: page.path,
          params,
        });
      }
    }
    service.saveTabsInfo();
  },
  /** 根据路径创建新Tab页 */
  createNewTabByPath(path: string, params?: RouteParams, returnPage: boolean = false) {
    const _currentPageIndex = state.pageTabs.findIndex(i => i.path === path);
    if (_currentPageIndex >= 0) {
      const _page = state.pageTabs[_currentPageIndex];
      state.activePage = _page.path as string;
      if (returnPage) {
        router.replace({
          ..._page,
          params,
        });
      }
    } else {
      let _pages: RouteRecordRaw | undefined;
      if (_pages) {
        state.activePage = _pages.path as string;
        state.pageTabs.push({
          path: _pages.path,
          fullPath: _pages.path,
          name: _pages.name,
          title: (_pages.meta?.['title'] || '') as string,
          fixed: false,
          params,
          meta: {},
        });
        if (returnPage) {
          router.replace({
            path: _pages.path,
            params,
          });
        }
      }
    }
    service.saveTabsInfo();
  },
  /** 创建新Tab页 */
  createNewTabByPage(page: TabPageItem, returnPage: boolean = false) {
    const _pageIndex = state.pageTabs.findIndex(i => i.path === page.path);
    if (_pageIndex >= 0) {
      state.activePage = state.pageTabs[_pageIndex].fullPath as string;
      router.replace(page);
    } else {
      state.pageTabs.push(page);
      router.push(page);
    }
    if (returnPage) {
      router.replace(page);
    }
    service.saveTabsInfo();
  },
};

/** 上一次的路由 */
let _prevPage = {} as any;

setTimeout(() => {
  router.afterEach((to, from) => {
    NProgress.done();
    _prevPage = to;
    const replace = sessionStorage.getItem('back_replace');
    if (to.query.replace || replace) {
      const _index = state.pageTabs.findIndex(i => from.fullPath === i.path);
      state.pageTabs.splice(_index, 1);
      sessionStorage.removeItem('back_replace');
    }

    if (['/404'].includes(to.fullPath)) {
      const _index = state.pageTabs.findIndex(i => _prevPage.fullPath === i.path);
      state.pageTabs.splice(_index, 1);
      return;
    }
    const _index = state.pageTabs.findIndex(i => to.path === i.path);
    if (from.fullPath !== to.fullPath) {
      if (to.path === '/externa-link') {
        const _pageIndex = state.pageTabs.findIndex(i => i.fullPath === to.fullPath);
        if (_pageIndex < 0) {
          state.pageTabs.push({
            path: to.path,
            fullPath: to.fullPath,
            name: to?.query?.src as string,
            title: (to?.query?.title as string) ?? '外链页面',
            fixed: false,
            meta: {
              link: to?.query?.src,
              title: to?.query?.title,
            },
          });
        }
        state.activePage = to?.query?.src as string;
      } else if (_index >= 0) {
        state.activePage = state.pageTabs[_index].fullPath;
      } else if (to.path && typeof to.path === 'string') {
        if (!globalState.excludePage.some(item => item.startsWith(to.path))) {
          if (!['/', '/home'].includes(to.path) && state.pageTabs.some(tab => tab.path !== to.path)) {
            const route = router.getRoutes();
            let _title = route.find(i => {
              const _params = i.path.split('/');
              const _toParams = to.path.split('/');
              if (_params.length !== _toParams.length) return false;
              const _checked = _params.every((item, index) => item.startsWith(':') || item === _toParams[index]);
              if (_checked === false) return false;
              return _params.length === _toParams.length;
            })?.meta?.title as string;
            if (!_title) _title = to?.query?.title as string;
            if (!_title) _title = globalState.pageList.find(i => i.path === to.path)?.menuName;
            nextTick(() => {
              state.activePage = to.fullPath;
            });
            state.pageTabs.push({
              path: to.path,
              fullPath: to.fullPath,
              name: to.name,
              title: _title || to.path,
              fixed: false,
              meta: {},
            });
          } else if (
            (from.path === '/' || globalState.excludePage.some(item => item.startsWith(from.path))) &&
            !state.pageTabs.some(tab => tab.path === '/home')
          ) {
            state.pageTabs.push({
              path: '/home',
              fullPath: '/home',
              name: to.name,
              title: '主页',
              fixed: false,
              meta: {},
            });
          }
          // TODO:
          state.activePage = state.pageTabs?.[state.pageTabs.length - 1]?.path ?? to.path;
        }
      }
    }
    service.saveTabsInfo();
  });
}, 10);

service.initTabsInfo();

/** 页面Tab模块 */
export default {
  /** 页面Tab模块状态 */
  state,
  /** 页面Tab模块逻辑 */
  service,
};
