
import Color from '@/lib/version-manage/color/Color';
import { toRefs, reactive, ref, computed, PropType, defineComponent } from 'vue';

export default defineComponent({
  name: 'CodeEditor',
  props: {
    /** 当前颜色 */
    value: {
      type: String,
      default: '',
    },
    /** 显示透明色 */
    showAlpha: {
      type: Boolean,
      default: true,
      required: true,
    },
    /** 颜色类型 */
    colorType: {
      type: String as PropType<'hex' | 'rgb' | 'hsl' | 'hsv'>,
      default: 'rgb',
      required: true,
    },
    /** 是否可切换颜色类型 */
    canChangeColorType: {
      type: Boolean,
      default: false,
      required: true,
    },
    /** 是否可清空 */
    canClear: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String as PropType<'large' | 'default' | 'small'>,
      default: 'default',
      required: true,
    },
  },
  setup() {
    const colorInput = ref({} as any);
    const colorPicker = ref({} as any);
    const colorPickerDisk = ref({} as any);

    const state = reactive({
      /** 是否开始选择 */
      showPicker: false,
      /** 弹框展开方向 */
      showPickerTop: true,
      /** 是否开始在色板拖拽取色 */
      isStartDrag: false,
      /** 色板游标离上部距离 */
      cursorTop: 0,
      /** 色板游标离左部距离 */
      cursorLeft: 0,
      /** 旧色值 */
      oldValue: '#FFFFFF',
      /** 新色值 */
      newValue: '',
      /** 颜色 */
      color: {} as Color,
      /** 文本框元素 */
      colorInput,
      /** 弹出框元素 */
      colorPicker,
      /** 画板元素 */
      colorPickerDisk,
      /** 颜色历史记录 */
      history: [] as string[],
      /** 是否显示下拉框 */
      isShowPicker: false,
    });

    return {
      ...toRefs(state),
    };
  },
  computed: {
    /** 色环值 */
    hue: {
      get() {
        return this.color.get('hue');
      },
      set(value) {
        this.color.set('hue', value);
        this.newValue = this.color.value;
      },
    },
    /** 透明度 */
    alpha: {
      get() {
        return this.color.get('alpha');
      },
      set(value) {
        this.color.set('alpha', value);
        this.newValue = this.color.value;
      },
    },
    /** 调色板的背景色 */
    diskBackGround() {
      return `hsl(${this.color.get('hue')}, 100%, 50%)`;
    },
  },
  created() {
    this.color = new Color({
      enableAlpha: this.showAlpha,
      format: this.colorType,
    }) as Color;
  },
  mounted() {
    this.init();
  },
  destroy() {
    document.body.addEventListener('mouseup', this.handleEndDrag);
    document.body.addEventListener('mousemove', this.handleDrag);
    document.body.addEventListener('mousedown', this.shrinkPicker);
  },
  methods: {
    /** 颜色改变函数 */
    changeColor(e?) {
      const value = e ? e.target.value : this.color.value;

      if (this.value !== value) {
        const rect = this.colorPickerDisk.getBoundingClientRect();
        this.cursorLeft = (this.color.get('saturation') * rect.width) / 100;
        this.cursorTop = ((100 - this.color.get('value')) * rect.height) / 100;

        this.color.fromString(value.trim().toLowerCase());
      }
    },
    /** 设置透明度（0 ~ 1） */
    setAlpha(alpha: number) {
      const { r, g, b } = this.color.toRgb();
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    },
    /** 初始化 */
    init() {
      this.color.fromString(this.value.trim().toLowerCase());
      this.color.format = this.colorType;
      this.alpha = this.color._alpha;
      document.body.addEventListener('mouseup', this.handleEndDrag);
      document.body.addEventListener('mousemove', this.handleDrag);
      document.body.addEventListener('mousedown', this.shrinkPicker);

      let _historyList: any = sessionStorage.getItem('colorPickerHistory');

      if (!_historyList || !_historyList.length) {
        const _color = new Color({
          enableAlpha: this.showAlpha,
          format: this.colorType,
        });
        _historyList = [];
        _color.fromString('rgba(255, 255, 255, 1)');
        _historyList.push(_color.value);
        _color.fromString('rgba(0, 0, 0, 1)');
        _historyList.push(_color.value);
      } else _historyList = JSON.parse(_historyList);
      this.history = _historyList;

      const rect = this.colorPickerDisk.getBoundingClientRect();
      this.cursorLeft = (this.color.get('saturation') * rect.width) / 100;
      this.cursorTop = ((100 - this.color.get('value')) * rect.height) / 100;
    },
    handleStartDrag(e) {
      this.isStartDrag = true;
      this.handleDrag(e);
    },
    /** 在色板取色 */
    handleDrag(e) {
      if (this.isStartDrag) {
        const rect = this.colorPickerDisk.getBoundingClientRect();
        let left = e.pageX - rect.left;
        let top = e.pageY - rect.top;
        left = Math.min(Math.max(0, left), rect.width);
        top = Math.min(Math.max(0, top), rect.height);
        this.cursorLeft = left;
        this.cursorTop = top;
        this.color.set({
          saturation: (left / rect.width) * 100,
          value: 100 - (top / rect.height) * 100,
        });
        this.newValue = this.color.value;
      }
    },
    /** 拖拽完毕 */
    handleEndDrag() {
      this.isStartDrag = false;
    },
    /** 选择历史颜色 */
    selectHistoryColor(item) {
      this.setColor(item);
    },
    setColor(color: string) {
      this.newValue = color;
      this.color.fromString(this.newValue);

      const rect = this.colorPickerDisk.getBoundingClientRect();
      this.cursorLeft = (this.color.get('saturation') * rect.width) / 100;
      this.cursorTop = ((100 - this.color.get('value')) * rect.height) / 100;

      this.comfirm();
    },
    /** 切换颜色类型 */
    changeColorType() {
      let { colorType } = this;
      switch (colorType) {
        case 'rgb':
          colorType = 'hsv';
          break;
        case 'hsv':
          colorType = 'hsl';
          break;
        case 'hsl':
          colorType = 'hex';
          break;
        case 'hex':
          colorType = 'rgb';
          break;
        default:
          break;
      }
      this.$emit('update:colorType', colorType);
      this.color.format = this.colorType;
      this.setColor(this.color.value);
    },
    /** 展开 */
    extendPicker() {
      const { y } = this.colorInput.getBoundingClientRect();
      const { height } = document.body.getBoundingClientRect();
      this.showPickerTop = height - y < 380;
      this.showPicker = true;

      this.init();
    },
    /** 收缩 */
    shrinkPicker() {
      this.showPicker = false;
    },
    /** 确定 */
    comfirm() {
      this.oldValue = this.newValue;
      this.$emit('input', this.newValue);
      this.$emit('update:value', this.newValue);
      this.$emit('change', this.newValue);
      this.showPicker = false;

      const _color = new Color({
        enableAlpha: this.showAlpha,
        format: this.colorType,
      });
      if (this.history.length > 20) {
        this.history.splice(0, 1);
      }
      _color.fromString(this.newValue);
      if (!this.history.includes(_color.value)) {
        this.history.push(_color.value);
        sessionStorage.setItem('colorPickerHistory', JSON.stringify(this.history));
      }
    },
  },
});
