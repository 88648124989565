import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_switch = _resolveComponent("a-switch")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_switch, {
      checked: _ctx.value,
      "checked-children": _ctx.checkedChildren,
      "un-checked-children": _ctx.unCheckedChildren,
      onChange: _ctx.change
    }, null, 8, ["checked", "checked-children", "un-checked-children", "onChange"])
  ]))
}