
import { defineComponent, reactive, toRefs, onMounted, PropType, watch } from 'vue';
import { dateFormat, getDataSource } from '@/tools/common';
import { message } from 'ant-design-vue';
import { ComponentProfile } from '@/@types/version-manage/component/component-profile';
import { storeToRefs } from 'pinia';
import { useProduct } from '@/modules/version-manage';

export default defineComponent({
  components: {},
  props: {
    /** 数据 */
    data: {
      type: Object as PropType<ComponentProfile>,
      default: () => ({}),
    },
  },
  setup(props) {
    const formState = reactive({
      filter: {},
      /** 是否加载中 */
      isLoading: false,
      /** 详情表数据 */
      dataSource: [] as any[],
      /** 编辑期刊信息Id */
      editUserId: 0,
      /** 是否显示弹出编辑框 */
      editDrawerShow: false,
    });

    /** 查询 */
    const search = (): Promise<any> => {
      return new Promise((resolve, reject) => {
        const hide = message.loading('加载中...', 0);
        formState.isLoading = true;

        getDataSource<any[]>(props.data.dataSource)
          .then(d => {
            formState.dataSource = d;
            resolve(d);
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            hide();
            formState.isLoading = false;
          });
      });
    };

    const init = () => {
      search();
    };

    /** 重设筛选项 */
    const reset = () => {
      props.data.filterConfig?.filterItems.forEach(filter => {
        formState.filter[filter.name as string] = undefined;
      });
      search();
    };

    /** 监听data */
    watch(
      () => props.data,
      data => {
        if (data) {
          search();
        }
      },
    );

    /** 配置重设 */
    const configReset = () => {
      formState.filter = {};
      search();
    };

    onMounted(() => {
      search();
    });

    return {
      ...toRefs(formState),
      global: storeToRefs(useProduct()),
      dateFormat,
      search,
      reset,
      init,
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {},
});
