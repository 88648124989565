
import { computed, defineComponent, PropType, reactive, toRefs } from 'vue';

export default defineComponent({
  name: 'ConditionPicker',
  props: {
    /** 值类型 */
    valueType: {
      type: String as PropType<'string' | 'select' | 'number' | 'else'>,
      default: 'string',
      required: true,
    },
    /** 参考选择项 */
    selectOptions: {
      type: Array as PropType<{ value: string; label: string }[]>,
      default: () => [],
    },
    size: {
      type: String as PropType<'large' | 'default' | 'small'>,
      default: 'default',
      required: true,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      /** 绑定值 */
      inputValue: '',
    });

    switch (props.valueType) {
      case 'string':
        emit('input', '=');
        break;
      case 'select':
        emit('input', props.selectOptions[0].value);
        break;
      case 'else':
        emit('input', '=');
        break;
      default:
        break;
    }

    const conditionList = computed(() => {
      switch (props.valueType) {
        case 'string':
          return [
            { value: '=', label: '等于' },
            { value: '!=', label: '不等于' },
            { value: '>', label: '大于' },
            { value: '>=', label: '大于等于' },
            { value: '<', label: '小于' },
            { value: '<=', label: '小于等于' },
            { value: 'like', label: '包含' },
            { value: 'notlike', label: '不包含' },
            { value: 'empty', label: '为空' },
            { value: 'notempty', label: '不为空' },
            { value: 'startwith', label: '以__开头' },
            { value: 'endwith', label: '以__结尾' },
          ];
        case 'select':
          return [...props.selectOptions];
        case 'number':
          return [
            { value: '=', label: '等于' },
            { value: '!=', label: '不等于' },
            { value: '>', label: '大于' },
            { value: '>=', label: '大于等于' },
            { value: '<', label: '小于' },
            { value: '<=', label: '小于等于' },
            { value: 'empty', label: '为空' },
            { value: 'notempty', label: '不为空' },
          ];
        case 'else':
          return [
            { value: 'empty', label: '为空' },
            { value: 'notempty', label: '不为空' },
          ];
        default:
          return [];
      }
    });

    return {
      ...toRefs(state),
      conditionList,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    search(inputValue: string, treeNode: any) {
      return `${treeNode.data.props.value}`.includes(inputValue) || treeNode.data.props.title.includes(inputValue);
    },
    /** 初始化 */
    init() {},
    /** 改变值 */
    change(val) {
      this.$emit('input', this.inputValue);
      this.$emit('change', this.inputValue);
    },
  },
});
