
import { computed, defineComponent, PropType, reactive, toRefs } from 'vue';

export default defineComponent({
  name: 'BoxEditor',
  props: {
    value: {
      type: Object as PropType<[number, number, number, number]>,
      default: () => [0, 0, 0, 0] as [number, number, number, number],
    },
    styleName: {
      type: String as PropType<'padding' | 'margin'>,
    },
  },
  setup() {
    const state = reactive({
      valueLeft: 0,
      valueRight: 0,
      valueTop: 0,
      valueBottom: 0,
    });

    const valueToArray = computed(() => {
      return [+state.valueTop, +state.valueRight, +state.valueBottom, +state.valueLeft];
    });

    return {
      ...toRefs(state),
      valueToArray,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    /** 初始化 */
    init() {
      [this.valueTop, this.valueRight, this.valueBottom, this.valueLeft] = this.value;
    },
    /** 改变值 */
    change() {
      if (this.valueToArray.join(',') !== this.value.join(',')) {
        this.$emit('change', this.valueToArray);
      }
    },
    input() {
      if (this.valueToArray.join(',') !== this.value.join(',')) {
        this.$emit('input', this.valueToArray);
      }
    },
  },
});
