import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      title: '产品发布分支图',
      width: 750,
      visible: _ctx.visible,
      footer: null,
      "body-style": { padding: '20px' },
      onCancel: _ctx.onClose
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          id: _ctx.elementId,
          class: "version-chart"
        }, null, 8, _hoisted_1)
      ]),
      _: 1
    }, 8, ["visible", "onCancel"])
  ]))
}