import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_button, {
      type: "primary",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDialog()))
    }, {
      icon: _withCtx(() => [
        _createVNode(_component_plus_outlined)
      ]),
      default: _withCtx(() => [
        _createTextVNode(" " + _toDisplayString(_ctx.data.label), 1)
      ]),
      _: 1
    })
  ]))
}