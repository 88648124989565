import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a8f8c9ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_input_number = _resolveComponent("a-input-number")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_input_number, {
      value: _ctx.inputValue,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.inputValue) = $event)),
      size: "small",
      style: {"width":"100%"},
      onChange: _ctx.change
    }, {
      addonAfter: _withCtx(() => [
        _createVNode(_component_a_select, {
          value: _ctx.suffix,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.suffix) = $event)),
          size: "small",
          style: {"width":"60px"},
          onChange: _ctx.change
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_select_option, { value: "px" }, {
              default: _withCtx(() => [
                _createTextVNode("px")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "%" }, {
              default: _withCtx(() => [
                _createTextVNode("%")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "vw" }, {
              default: _withCtx(() => [
                _createTextVNode("vw")
              ]),
              _: 1
            }),
            _createVNode(_component_a_select_option, { value: "vh" }, {
              default: _withCtx(() => [
                _createTextVNode("vh")
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "onChange"])
      ]),
      _: 1
    }, 8, ["value", "onChange"])
  ]))
}