import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68d60117"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "color-picker-slider" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "slider",
      class: "color-picker-slider-content",
      style: _normalizeStyle(_ctx.sliderStyle),
      onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.startDrag && _ctx.startDrag(...args)))
    }, [
      _createElementVNode("div", {
        style: _normalizeStyle({ left: _ctx.cursorLeft + 'px' }),
        class: "color-picker-slider-bar"
      }, null, 4)
    ], 36)
  ]))
}