import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-455e65b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "date-picker" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_date_picker = _resolveComponent("a-date-picker")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_date_picker, {
      value: _ctx.inputValue,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      placeholder: _ctx.placeholder,
      onOk: _ctx.change,
      onChange: _ctx.input
    }, null, 8, ["value", "placeholder", "onOk", "onChange"])
  ]))
}