
import { getProductList, getProjectDetail, editProject, addProject } from '@/api/version-manage';
import { upload } from '@/tools/api';
import { useProduct } from '@/modules/version-manage';
import { getBase64 } from '@/tools/common';
import { AsyncReturnType } from '@/@types';
import { message, UploadChangeParam } from 'ant-design-vue';
import { defineComponent, reactive, toRefs, ref, watch, PropType, onMounted } from 'vue';

export default defineComponent({
  components: {},
  props: {
    /** 是否显示 */
    visible: {
      type: Boolean,
      default: false,
    },
    /** 项目Id */
    id: {
      type: Number as PropType<number | undefined>,
    },
    /** 关联产品Id */
    productId: {
      type: Number as PropType<number | undefined>,
    },
    /** 是否禁用 */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const global = useProduct();

    const formRef = ref();

    const state = reactive({
      /** 已选择来源 */
      selectedSourceList: [] as Record<string, any>[],
      /** 来源列表 */
      sourceList: [] as Record<string, any>[],
      /** 是否加载中 */
      isLoading: false,
      /** 作者列表 */
      authorList: [] as string[],
      /** 上传中 */
      isUploadLoading: false,
      /** 上传列表 */
      uploadList: [] as any[],
      /** 表单 */
      form: {
        /** 关联产品Id */
        productId: null as number | null,
        /** 项目名称 */
        title: '',
        /** 项目代码 */
        productCode: '',
        /** 项目类型 */
        productType: '',
        /** 描述 */
        description: '',
        /** 是否为医疗产品 */
        isStrictly: false,
        /** 项目负责人 */
        productOwner: '',
        /** 是否启用 */
        isActive: true,
        /** Jenkins配置 */
        jenkinsConfig: {},
        /** 备注 */
        productDetailRemark: '',
      },
      /** 图标是不能作为表单项进行提交的 那么提取出来 */
      icon: '',
      /** 表单规则 */
      rules: {
        title: [{ required: true, message: '请输入项目名称' }],
        productCode: [{ required: true, message: '请输入项目代码' }],
        productType: [{ required: true, message: '请选择项目类型' }],
        productId: [{ required: true, message: '请选择产品线' }],
      },
      /** 加载产品列表 */
      loadingProductList: false,
      /** 关联产品列表 */
      productList: [] as AsyncReturnType<typeof getProductList>['rows'],
    });

    /** 清空数据 */
    const clear = () => {
      state.form = {
        productId: null,
        title: '',
        productCode: '',
        productType: '',
        description: '',
        isStrictly: false,
        productOwner: '',
        isActive: true,
        jenkinsConfig: {},
        productDetailRemark: '',
      };
      state.uploadList = [];
    };
    const handleChange = (info: UploadChangeParam) => {
      if (info.file.status === 'uploading') {
        state.isUploadLoading = true;
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (base64Url: string) => {
          state.icon = base64Url;
          state.isUploadLoading = false;
        });
      }
      if (info.file.status === 'error') {
        state.isUploadLoading = false;
        message.error('upload error');
      }
    };

    /** 上传文件 */
    const uploadFile = (projectId?: number): Promise<void> => {
      return new Promise((resolve, reject) => {
        if (state.uploadList.length && state.uploadList[0].uid !== 'init-logo') {
          const hide = message.loading('上传中', 0);
          state.isUploadLoading = true;
          upload('/system/program/ver/version/logo/upload', {
            file: state.uploadList.pop().originFileObj,
            id: projectId ?? props.id,
            type: 2,
          })
            .then(() => {
              resolve();
            })
            .catch(err => {
              reject(err);
            })
            .finally(() => {
              hide();
              state.isUploadLoading = false;
            });
        } else {
          resolve();
        }
      });
    };

    const beforeUpload = file => {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('图标文件最大为2mb。');
      }
      if (isLt2M) {
        state.uploadList = [...state.uploadList, file];
      }
      return false;
    };
    onMounted(() => {
      state.loadingProductList = true;
      getProductList({
        pageNum: 1,
        pageSize: 9999,
      })
        .then(data => {
          if (data) {
            state.productList = data.rows;
          }
        })
        .finally(() => {
          state.isLoading = false;
          state.loadingProductList = false;
        });
    });

    watch(
      () => props.visible,
      visible => {
        if (visible) {
          clear();
          if (props.id) {
            state.isLoading = true;
            getProjectDetail({
              id: props.id,
            })
              .then(data => {
                if (data) {
                  state.form = {
                    title: data.title,
                    productId: data.productId,
                    productCode: data.projectCode,
                    productType: data.projectType,
                    description: data.description,
                    isStrictly: data.strictly,
                    productOwner: data.projectManager,
                    isActive: data.enable,
                    jenkinsConfig: data.jenkinsConfig,
                    productDetailRemark: data.remark,
                  };
                  if (data.icon) {
                    const signRepeat = state.uploadList.find(item => item.url === data.icon);
                    if (!signRepeat) {
                      state.uploadList.push({
                        name: data.icon.substr(data.icon.lastIndexOf('/') + 1),
                        status: 'done',
                        thumbUrl: data.icon,
                        url: data.icon,
                        uid: 'init-logo',
                      });
                    }
                  }
                }
              })
              .finally(() => {
                state.isLoading = false;
              });
          } else if (props.productId) state.form.productId = props.productId;
        }
      },
    );

    return {
      ...toRefs(state),
      global,
      formRef,
      state,
      clear,
      uploadFile,
      handleChange,
      beforeUpload,
    };
  },
  created() {},
  methods: {
    onClose() {
      this.clear();
      this.$emit('update:visible', false);
    },
    onSubmit() {
      this.isLoading = true;
      const theForm = this.form;
      const newForm = {
        title: theForm.title,
        productId: theForm.productId,
        projectCode: theForm.productCode,
        projectType: theForm.productType,
        description: theForm.description,
        strictly: theForm.isStrictly,
        projectManager: theForm.productOwner,
        enable: theForm.isActive,
        jenkinsConfig: theForm.jenkinsConfig,
        remark: theForm.productDetailRemark,
      };
      this.formRef
        .validate()
        .then(() => {
          if (this.id) {
            Promise.all([
              editProject({
                ...newForm,
                id: this.id,
              }),
              this.uploadFile(),
            ])
              .then(d => {
                this.$emit('update:visible', false);
                this.$emit('submit', d);
              })
              .finally(() => {
                this.isLoading = false;
              });
          } else {
            addProject({
              ...newForm,
            })
              .then(d => {
                if (d.id) {
                  this.uploadFile(d.id).then(d1 => {
                    this.$emit('update:visible', false);
                    this.$emit('submit', d);
                  });
                } else {
                  this.$emit('update:visible', false);
                  this.$emit('submit', d);
                }
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        })
        .catch(error => {
          this.isLoading = false;
          console.error('error', error);
        });
    },
  },
});
