
import { defineComponent, PropType } from 'vue';
import { ComponentFlex } from '@/@types/version-manage/component/component-flex';
import { useProduct } from '@/modules/version-manage';
import ComponentFlexItem from './ComponentFlexItem.vue';

export default defineComponent({
  components: {
    ComponentFlexItem,
  },
  props: {
    /** 数据 */
    data: {
      type: Object as PropType<ComponentFlex>,
      default: () => ({}),
    },
  },
  setup(props) {
    const global = useProduct();

    return {
      global,
    };
  },
  created() {},
  mounted() {},
  methods: {},
});
