
import { defineComponent, PropType, computed } from 'vue';
import { FormItem } from '@/@types/version-manage/form';
import { useProduct } from '@/modules/version-manage';

export default defineComponent({
  components: {},
  props: {
    /** 属性 */
    prop: {
      type: Object as PropType<FormItem>,
      required: true,
      default: () => ({}),
    },
    /** 值 */
    value: {},
  },
  setup(props) {
    const global = useProduct();

    const getEditor = computed(() => {
      return global.propertyEditors[props.prop.editor];
    });

    return {
      global,
      getEditor,
    };
  },
  created() {},
  mounted() {},
  methods: {
    /** 属性修改触发的事件 */
    propChangeListener(val) {
      if (val.target) {
        console.warn('属性值包含val.target', val);
        return;
      }
      this.$emit('update:value', val);
      this.$emit('change', val);
    },
  },
});
