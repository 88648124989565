
import { useProduct } from '@/modules/version-manage';
import { message } from 'ant-design-vue';
import { defineComponent, reactive, toRefs, ref, watch, onMounted, PropType } from 'vue';

export default defineComponent({
  components: {},
  props: {
    /** 配置 */
    configs: {
      type: Array as PropType<Record<string, any>[]>,
      default: () => [],
    },
    /** 表单宽度 */
    width: {
      type: Number,
      default: 1000,
    },
    /** 是否显示 */
    visible: {
      type: Boolean,
      default: false,
    },
    /** 是否禁用 */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** 表单校验规则 */
    rules: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
  },
  setup(props) {
    const global = useProduct();

    const formRef = ref();

    const state = reactive({
      /** 是否加载中 */
      isLoading: false,
      /** 表单内容 */
      form: {
        /** 字段列表 */
        fields: [] as Record<string, any>[],
      } as Record<string, any>,
    });

    watch(
      () => props.visible,
      count => {
        if (count) {
          if (props.configs?.length) {
            state.form.fields = props.configs;
          } else {
            state.form.fields = [];
          }
        }
      },
    );

    onMounted(() => {});

    return {
      ...toRefs(state),
      global,
      formRef,
    };
  },
  methods: {
    /** 删除配置项 */
    handleDeleteConfig(fieldIndex) {
      this.form.fields.splice(fieldIndex, 1);
      this.$emit('deleteConfig', this.form);
    },
    /** 新增配置项 */
    addConfig() {
      this.form.fields.push({
        type: 'string',
        required: true,
      });
    },
    /** 清空表单 */
    clear() {},
    /** 关闭弹出框 */
    onClose() {
      this.clear();
      this.$emit('update:visible', false);
    },
    /** 表单提交 */
    onSubmit() {
      this.formRef
        .validate()
        .then(() => {
          this.$emit('submit', this.form);
          this.$emit('update:visible', false);
        })
        .catch(() => {
          message.info('数据格式错误');
        });
    },
  },
});
