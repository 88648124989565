import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_table, {
      size: "middle",
      scroll: { x: 1500, y: 900 },
      columns: _ctx.columns,
      "data-source": _ctx.dataSource,
      loading: _ctx.isLoading,
      pagination: _ctx.data.pagination === false ? false : _ctx.pagination,
      onChange: _ctx.handleTableChange
    }, {
      bodyCell: _withCtx(({ column, index, record }) => [
        (column.type === 'date')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.dateFormat(new Date(record.birthday), 'yyyy-MM-dd HH:mm:ss')), 1)
            ], 64))
          : _createCommentVNode("", true),
        (column.key === 'action')
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.data.rowOperateConfig, (item, itemIndex) => {
              return (_openBlock(), _createElementBlock(_Fragment, null, [
                (item.operateType === 'update')
                  ? (_openBlock(), _createBlock(_component_a_button, {
                      key: `update_${index}_${itemIndex}`,
                      type: "link",
                      onClick: ($event: any) => (_ctx.showDrawer(item, record))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.label || '编辑'), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  : (item.operateType === 'remove')
                    ? (_openBlock(), _createBlock(_component_a_popconfirm, {
                        key: `remove_${index}_${itemIndex}`,
                        placement: "topRight",
                        title: `是否${item.label || '删除'}当前数据？`,
                        "ok-text": "确认",
                        "cancel-text": "取消",
                        onConfirm: ($event: any) => (_ctx.removeItem(record))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, {
                            danger: "",
                            style: {"margin-left":"10px"}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.label || '删除'), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1032, ["title", "onConfirm"]))
                    : _createCommentVNode("", true),
                _createTextVNode("   ")
              ], 64))
            }), 256))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["columns", "data-source", "loading", "pagination", "onChange"])
  ]))
}