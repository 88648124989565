/** 医生性别 */
export enum DoctorGender {
  unknow = '0',
  male = '1',
  female = '2',
}

/** 获取医生列表字段 */
export interface DocListItem {
  /** 医院 id */
  hospitalId: number;
  /** 科室 id */
  hospitalDeptId?: number;
  /** 医生姓名 */
  name?: string;
  /** 医生手机号 */
  phonenumber?: string;
  sex?: string;
  /** 账号状态 */
  status?: string;
  /** 医生拥有的标签 */
  tagIds?: string;
  pageNum: number;
  pageSize: number;
  /** 年龄排序 */
  orderByAge: number;
  /** 角色标识查询 */
  roleKeys?: Array<string>;
}

/** 标签是显示还是隐藏 */
export enum LabelIsShow {
  Show,
  Hidden,
}

/** 标签是否必填 */
export enum LabelIsRequire {
  Require,
  Hidden,
}

/** 获取标签 */
export interface GetLabelItem {
  /** 标签 id */
  tagTypeId: number;
  pageNum?: number;
  pageSize?: number;
}
/** 添加子标签 */
export interface AddSonLabelItem {
  /** 医院 id */
  hospitalId: number;
  /** 标签显示与否 */
  isDeleted: number;
  /** 标签名 */
  tagContent: string;
  /** 父标签id */
  tagTypeId: number;
  /** 子标签id */
  tagDataId?: number;
}

/** 新增或者编辑医生字段 */
export interface AddDocItem {
  /** 出生日期 */
  birthday?: string;
  /** 医院 id */
  hospitalId?: number;
  name: string;
  phoneNumber: string;
  /** 医生拥有的标签 */
  tagIds?: Array<number>;
  sex?: string;
  /** 医生照片 */
  photo?: string;
  /** 医生 id */
  userId?: number;
  /** 医生简介 */
  profile?: string;
  /** 医生状态 */
  status?: string;
}

/** 查询操作日志 */
export interface SearchDocLogItem {
  /** 哪个角色的操作日志 */
  beOperateRole: string;
  /** 哪个人操作产生 */
  createName?: string;
  endTime?: string;
  startTime?: string;
  /** 医院 id */
  hospitalId: number;
  pageNum: number;
  pageSize: number;
  /** 操作类型 */
  type?: string;
}

/** 改变医生状态 */
export interface ChangeDocStaItem {
  /** 医院 id */
  hospitalId: number;
  /** 医生状态 */
  status: string;
  /** 用户 id */
  userId: number;
}

/** 日志是谁操作 */
export enum WhoCreateLog {
  /** 医生 */
  DOCTOR = 'DOCTOR',
  /** 患者 */
  PATIENT = 'PATIENT',
  /**  量表 */
  SCALE = 'SCALE_TOOL',
  /** 采集视频 */
  COLLECT_VIDEO_TOOL = 'COLLECT_VIDEO_TOOL',
  /** 采集音频 */
  COLLECT_AUDIO_TOOL = 'COLLECT_AUDIO_TOOL',
  /** 训练 */
  TRAIN_TOOL = 'TRAIN_TOOL',
  /** 任务组 */
  TASK_GROUP = 'TASK_GROUP',
  /** OCR */
  OCR = 'OCR_RECORD',
  FOLLOW_UP = 'FOLLOW_UP',
}
/** 日志是谁操作 */
export enum OrderAge {
  /** 降序 */
  AgeDescend,
  /** 患者 */
  AgeAsend,
}

/** 患者列表排序 */
export enum PatientListSorter {
  /** 年龄正序 */
  AgeAsend = '00',
  /** 年龄倒序 */
  AgeDescend = '01',
  /** 就诊次数正序 */
  CountAsend = '10',
  /** 就诊次数倒序 */
  CountDescend = '11',
  /** 就诊时间正序 */
  TimeAsend = '20',
  /** 就诊时间倒序 */
  TimeDescend = '21',
}
/** 医生状态 */
export enum DoctorSta {
  /** 正常 */
  Normal = '0',
  /** 停用 */
  Stop = '1',
}
/** 医生端现有角色 */
export enum MedicalRole {
  /** 医生端凝动管理员 */
  medicalNdylAdmin = 'medicalNdylAdmin',
  /** 普通医生 */
  doctor = 'doctor',
  /** 医院管理员 */
  hospitalAdministrator = 'hospitalAdministrator',
}
