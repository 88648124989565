
import { defineComponent, PropType, reactive, toRefs } from 'vue';

export default defineComponent({
  props: {
    value: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    component: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    },
    placeholder: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup() {
    const state = reactive({
      inputValue: [] as string[],
    });
    return {
      ...toRefs(state),
    };
  },
  mounted() {
    this.init();
  },
  unmounted() {
    // if (this.inputValue != this.value) {
    //   this.$emit('change', this.inputValue);
    // }
  },
  methods: {
    /** 初始化 */
    init() {
      this.inputValue = this.value;
    },
    /** 改变值 */
    change(inputValue) {
      if (this.inputValue !== this.value) {
        this.$emit('change', [this.$attrs.name, this.getStrValue(inputValue ?? this.inputValue)]);
      }
    },
    /** 获取Value */
    getStrValue(vals: any[]) {
      return vals.map(i => {
        return i?.format?.('YYYY-MM-DD') || '';
      });
    },
  },
});
