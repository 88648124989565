
import { defineComponent, PropType, reactive, ref, toRefs, watch } from 'vue';
import WangEditor from 'wangeditor';
import bus from '@/tools/bus';
import { FormItem } from '@/@types/version-manage/form';

export default defineComponent({
  components: {},
  props: {
    value: {
      type: String,
      default: '',
      required: true,
    },
    component: {
      type: Object as PropType<FormItem>,
      default: () => ({}),
    },
  },
  setup(props) {
    const editor = ref();

    const state = reactive({
      /** 编辑器 */
      instance: undefined as WangEditor | undefined,
    });

    watch(
      () => props.value,
      () => {
        if (editor.value && state.instance) {
          if (state.instance.txt.html() !== props.value) {
            state.instance.txt.html(`${props.value}`);
          }
        }
      },
    );
    return {
      ...toRefs(state),
      editor,
    };
  },
  created() {},
  mounted() {
    this.onInit();
  },
  beforeUnmount() {
    bus.$off('component_change');
    this.onDispose();
  },
  methods: {
    /** 改变值 */
    change(value: string, isChange: boolean = false) {
      let val = value;
      let _value = this.value;
      if (val.startsWith('<p>') && val.endsWith('</p>')) {
        val = val.substring(3, val.length - 4);
      }
      if (_value.startsWith('<p>') && _value.endsWith('</p>')) {
        _value = _value.substring(3, _value.length - 4);
      }
      if (val !== _value) {
        this.$emit('update:value', val);
        if (isChange) {
          this.$emit('change', val);
        }
      }
    },
    onInit() {
      this.instance = new WangEditor(this.editor);
      Object.assign(this.instance.config, {
        onchange: () => {
          if (this.instance) {
            const _content = this.instance.txt.html() as string;
            this.change(_content);
          }
        },
      });
      this.instance.config.height = 200;
      this.instance.config.zIndex = 500;
      this.instance.config.fontSizes = {
        'x-small': { name: '10px', value: '1' },
        small: { name: '12px', value: '2' },
        normal: { name: '14px', value: '3' },
        large: { name: '16px', value: '4' },
        'x-large': { name: '20px', value: '5' },
        'xx-large': { name: '26px', value: '6' },
        'xxx-large': { name: '32px', value: '7' },
      };
      this.instance.config.menus = [
        'head',
        'bold',
        'fontSize',
        // 'fontName',
        'italic',
        'underline',
        'strikeThrough',
        'indent',
        // 'lineHeight',
        'link',
        'list',
        'foreColor',
        'backColor',
        'todo',
        // 'justify',
        'quote',
        'emoticon',
        'image',
        'video',
        'table',
        'code',
        'splitLine',
        'undo',
        'redo',
      ];
      this.instance.create();
      this.instance.txt.html(`${this.value}`);
      const _instance = this.instance;

      setTimeout(() => {
        if (_instance) {
          const ele = document.getElementById(_instance.textElemId);
          if (ele) {
            ele.setAttribute('tabindex', '-1');
            ele.addEventListener('blur', () => {
              const _content = _instance.txt.html() as string;
              this.change(_content, true);
            });
          }
        }
      }, 50);
    },
    onDispose(id?: string) {
      if (this.instance) {
        this.instance.destroy();
        this.instance = undefined;
      }
    },
  },
});
