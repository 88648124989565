
import { toRefs, reactive, ref, computed, PropType, defineComponent, inject } from 'vue';

export default defineComponent({
  name: 'VariablePicker',
  props: {
    /** 当前变量 */
    value: {
      type: String,
      default: '',
      required: true,
    },
    /** 筛选变量类型 */
    type: {
      type: String,
      default: 'any',
      required: true,
    },
    size: {
      type: String as PropType<'large' | 'default' | 'small'>,
      default: 'default',
      required: true,
    },
  },
  setup() {
    const state = reactive({
      variableList: [],
      /** 搜索文本 */
      searchTxt: '',
      /** 真实值 */
      inputValue: [''] as string[],
      extendNodes: [] as string[],
    });

    return {
      ...toRefs(state),
    };
  },
  watch: {
    variableList() {},
  },
  mounted() {
    this.init();
  },
  methods: {
    search(inputValue: string, treeNode: any) {
      return `${treeNode.data.props.value}`.includes(inputValue) || treeNode.data.props.title.includes(inputValue);
    },
    /** 初始化 */
    init() {
      this.inputValue = this.value.split('.');
    },
    /** 改变值 */
    change() {
      this.$emit('input', this.inputValue.join('.'));
    },
  },
});
