
import { defineComponent, PropType, reactive, toRefs } from 'vue';

export default defineComponent({
  name: 'TextEditor',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup() {
    const state = reactive({
      inputValue: '',
    });
    return {
      ...toRefs(state),
    };
  },
  mounted() {
    this.init();
  },
  unmounted() {
    // if (this.inputValue != this.value) {
    //   this.$emit('change', this.inputValue);
    // }
  },
  methods: {
    /** 初始化 */
    init() {
      this.inputValue = this.value;
    },
    /** 改变值 */
    change() {
      if (this.inputValue !== this.value) {
        this.$emit('change', this.inputValue);
      }
    },
    input() {
      if (this.inputValue !== this.value) {
        this.$emit('input', this.inputValue);
      }
    },
  },
});
