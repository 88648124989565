
import { useProduct } from '@/modules/version-manage';
import { post } from '@/lib/api';
import { message } from 'ant-design-vue';
import { RuleObject } from 'ant-design-vue/lib/form';
import { defineComponent, reactive, toRefs, ref } from 'vue';

export default defineComponent({
  components: {},
  props: {
    /** 是否显示 */
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const global = useProduct();

    const formRef = ref();

    const state = reactive({
      /** 已选择来源 */
      selectedSourceList: [] as Record<string, any>[],
      /** 来源列表 */
      roleList: [] as Record<string, any>[],
      /** 是否加载中 */
      isLoading: false,
      /** 表单 */
      form: {
        /** 旧密码 */
        oldPass: '',
        /** 新密码 */
        newPass: '',
        /** 重输新密码 */
        repeatNewPassword: '',
      },
    });

    /** 校验新密码 */
    const validateNewPass = async (_rule: RuleObject, value: string) => {
      if (value === state.form.oldPass) {
        return Promise.reject('旧密码和新密码不能相同');
      } else {
        return Promise.resolve();
      }
    };
    /** 校验重复密码 */
    const validateRepeatPass = async (_rule: RuleObject, value: string) => {
      if (value !== state.form.newPass) {
        return Promise.reject('两次输入密码不同');
      } else {
        return Promise.resolve();
      }
    };

    /** 表单规则 */
    const rules = {
      oldPass: [{ required: true, message: '请输入旧密码' }],
      newPass: [
        { required: true, message: '请输入新密码' },
        { pattern: /.{6,}/, message: '密码位数不能少于10位' },
        { validator: validateNewPass, trigger: 'change' },
      ],
      repeatNewPassword: [
        { required: true, message: '请重新输入新密码' },
        { validator: validateRepeatPass, trigger: 'change' },
      ],
    };

    return {
      ...toRefs(state),
      global,
      formRef,
      rules,
    };
  },
  created() {},
  mounted() {},
  methods: {
    onClose() {
      this.$emit('update:visible', false);
    },
    onSubmit() {
      this.isLoading = true;
      this.formRef
        .validate()
        .then(() => {
          post('/user/updatePass', {
            ...this.form,
          })
            .then(d => {
              message.success('密码修改成功，请重新登录');
              this.$emit('update:visible', false);
              this.$emit('submit', d);
              this.global.logout();
            })
            .finally(() => {
              this.isLoading = false;
            });
        })
        .catch(error => {
          this.isLoading = false;
          console.error('error', error);
        });
    },
  },
});
