
import { OperateConfig } from '@/@types/version-manage/page-config';
import { get, post, put } from '@/lib/api';
import { defineComponent, reactive, toRefs, ref, watch, computed, PropType } from 'vue';
import bus from '@/tools/bus';
import { getProductDetail, productEdit, addProduct } from '@/api/version-manage';

export default defineComponent({
  components: {},
  props: {
    /** 是否显示 */
    visible: {
      type: Boolean,
      default: false,
    },
    /** 对应编码 */
    code: {
      type: String,
      required: true,
    },
    /** 关联主键 */
    keyId: {
      type: String,
      default: '',
    },
    /** 数据 */
    data: {
      type: Object as PropType<OperateConfig>,
      required: true,
    },
  },
  setup(props) {
    const formRef = ref();

    const state = reactive({
      /** 是否加载中 */
      isLoading: false,
      /** 表单 */
      form: {},
      /** 表单规则 */
      rules: {},
    });

    /** 清空数据 */
    const clear = () => {
      state.form = {};
    };

    watch(
      () => props.visible,
      (count, prevCount) => {
        if (count) {
          if (props.keyId) {
            state.isLoading = true;
            getProductDetail({
              id: Number(props.keyId),
            })
              .then(data => {
                if (data) {
                  const _description = JSON.parse(data.description);
                  state.form = {
                    ...data,
                    description: Array.isArray(_description) ? _description : [_description],
                  };
                }
              })
              .finally(() => {
                state.isLoading = false;
              });
          } else {
            clear();
          }
        }
      },
    );

    return {
      ...toRefs(state),
      formRef,
      clear,
    };
  },
  created() {},
  mounted() {},
  methods: {
    /** 获取行数 */
    getRowCount() {
      const itemList = (this.data.form?.items ?? []).map(i => i.span || 1);
      let itemCount = 0;
      itemList.forEach(i => {
        itemCount += i;
      });
      return Math.ceil(itemCount / 2) + (itemCount % 2);
    },
    /** 修改多属性表单值 */
    changeMulValue([keys, vals]) {
      keys.forEach((item, index) => {
        this.form[item] = vals[index];
      });
    },
    /** 显示弹出框 */
    showDialog() {
      bus.$emit('showComponentDialog', {
        keyId: this.keyId,
        code: this.code,
      });
    },
    onClose() {
      this.clear();
      this.$emit('update:visible', false);
    },
    onSubmit() {
      this.isLoading = true;
      this.formRef
        .validate()
        .then(() => {
          if (this.keyId) {
            post('/system/program/ver/product/edit', {
              ...this.form,
              id: this.keyId,
            })
              .then(d => {
                this.$emit('update:visible', false);
                this.$emit('submit', d);
              })
              .finally(() => {
                this.isLoading = false;
              });
          } else {
            post('/system/program/ver/product/add', {
              ...this.form,
            })
              .then(d => {
                this.$emit('update:visible', false);
                this.$emit('submit', d);
              })
              .finally(() => {
                this.isLoading = false;
              });
          }
        })
        .catch(error => {
          this.isLoading = false;
          console.error('error', error);
        });
    },
  },
});
