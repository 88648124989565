import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { style: {"text-align":"right"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_modal, {
      title: 'Jenkins配置',
      width: _ctx.width,
      visible: _ctx.visible,
      onOk: _ctx.onSubmit,
      onCancel: _ctx.onClose
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_button, {
          style: {"margin-bottom":"30px"},
          type: "primary",
          onClick: _ctx.addConfig
        }, {
          default: _withCtx(() => [
            _createTextVNode("新增配置项")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_a_spin, {
          tip: "加载中...",
          spinning: _ctx.isLoading
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["check-form", { disabled: _ctx.disabled }])
            }, [
              _createVNode(_component_a_form, {
                ref: "formRef",
                layout: "horizontal",
                model: _ctx.form,
                rules: _ctx.rules
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.form.fields, (field, fieldIndex) => {
                    return (_openBlock(), _createBlock(_component_a_row, {
                      key: fieldIndex,
                      gutter: 16
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_col, { span: 5 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              label: `代码`,
                              name: ['fields', fieldIndex, 'key'],
                              rules: [{ required: true, message: '配置代码不能为空' }]
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input, {
                                  value: _ctx.form.fields[fieldIndex].key,
                                  "onUpdate:value": ($event: any) => ((_ctx.form.fields[fieldIndex].key) = $event)
                                }, null, 8, ["value", "onUpdate:value"])
                              ]),
                              _: 2
                            }, 1032, ["name"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_a_col, { span: 5 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              label: `名称`,
                              name: ['fields', fieldIndex, 'label']
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input, {
                                  value: _ctx.form.fields[fieldIndex].label,
                                  "onUpdate:value": ($event: any) => ((_ctx.form.fields[fieldIndex].label) = $event)
                                }, null, 8, ["value", "onUpdate:value"])
                              ]),
                              _: 2
                            }, 1032, ["name"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_a_col, { span: 5 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              label: `默认值`,
                              name: ['fields', fieldIndex, 'default']
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_input, {
                                  value: _ctx.form.fields[fieldIndex].default,
                                  "onUpdate:value": ($event: any) => ((_ctx.form.fields[fieldIndex].default) = $event)
                                }, null, 8, ["value", "onUpdate:value"])
                              ]),
                              _: 2
                            }, 1032, ["name"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_a_col, { span: 4 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              label: `类型`,
                              name: ['fields', fieldIndex, 'type']
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_select, {
                                  ref_for: true,
                                  ref: "select",
                                  value: _ctx.form.fields[fieldIndex].type,
                                  "onUpdate:value": ($event: any) => ((_ctx.form.fields[fieldIndex].type) = $event),
                                  style: {"width":"100%"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_select_option, { value: "string" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("文本")
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_a_select_option, { value: "int" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("整数")
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_a_select_option, { value: "boolean" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("真假")
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 2
                                }, 1032, ["value", "onUpdate:value"])
                              ]),
                              _: 2
                            }, 1032, ["name"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_a_col, { span: 4 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_form_item, {
                              label: `是否必填`,
                              name: ['fields', fieldIndex, 'required']
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_switch, {
                                  checked: _ctx.form.fields[fieldIndex].required,
                                  "onUpdate:checked": ($event: any) => ((_ctx.form.fields[fieldIndex].required) = $event)
                                }, null, 8, ["checked", "onUpdate:checked"])
                              ]),
                              _: 2
                            }, 1032, ["name"])
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_a_col, { span: 1 }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_1, [
                              _createVNode(_component_a_tooltip, null, {
                                title: _withCtx(() => [
                                  _createTextVNode("移除配置项")
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_a_button, {
                                    shape: "circle",
                                    danger: "",
                                    onClick: ($event: any) => (_ctx.handleDeleteConfig(fieldIndex))
                                  }, {
                                    icon: _withCtx(() => [
                                      _createVNode(_component_delete_outlined)
                                    ]),
                                    _: 2
                                  }, 1032, ["onClick"])
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["model", "rules"])
            ], 2)
          ]),
          _: 1
        }, 8, ["spinning"])
      ]),
      _: 1
    }, 8, ["width", "visible", "onOk", "onCancel"])
  ]))
}