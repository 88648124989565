import { Hospital } from '@/@types/hospital';
import { state as globalState } from '@/modules/global';

/** 存储医院列表信息 */
export const storeHospitalList = info => {
  localStorage.setItem(Hospital.HOSPITAL_LIST, JSON.stringify(info));
};

/** 获取医院列表信息 */
export const getHospitalList = () => {
  return JSON.parse(localStorage.getItem(Hospital.HOSPITAL_LIST) as string);
};

/** 存储所有医生最后一次登录医院列表 和 曾经选择的医院信息 */
export const storeUserHospital = info => {
  /** 需要存储的当前登录信息 */
  const needStoreInfo = { ...info, id: globalState.userInfo.id };
  if (localStorage.getItem(Hospital.NOW_USER_HOSPITAL_INFO_ARR)) {
    const loadedHospital = JSON.parse(localStorage.getItem(Hospital.NOW_USER_HOSPITAL_INFO_ARR) as string);
    /** 是否有上次登录记录 */
    let isLoadedIndex = -1;
    loadedHospital.forEach((item, index) => {
      if (item.id === globalState.userInfo.id) {
        isLoadedIndex = index;
      }
    });
    if (isLoadedIndex !== -1) {
      loadedHospital[isLoadedIndex] = needStoreInfo;
    } else {
      loadedHospital.push(needStoreInfo);
    }
    localStorage.setItem(Hospital.NOW_USER_HOSPITAL_INFO_ARR, JSON.stringify(loadedHospital));
  } else {
    localStorage.setItem(Hospital.NOW_USER_HOSPITAL_INFO_ARR, JSON.stringify([needStoreInfo]));
  }
};

/** 找到医生上次登录的医院信息并更新上次登录的医院信息 */
export const findLastHospital = () => {
  if (localStorage.getItem(Hospital.NOW_USER_HOSPITAL_INFO_ARR)) {
    const loadedHospital = JSON.parse(localStorage.getItem(Hospital.NOW_USER_HOSPITAL_INFO_ARR) as string);
    /** 找到之前登录的医院 */
    loadedHospital.forEach((i, index) => {
      if (i.id === globalState.userInfo.id) {
        const nowHospitalList = JSON.parse(localStorage.getItem(Hospital.HOSPITAL_LIST) as string);
        // 更新最新的医院信息
        loadedHospital[index] = { ...nowHospitalList.find(hos => hos.hospitalId === i.hospitalId), id: globalState.userInfo.id };
      }
    });
    localStorage.setItem(Hospital.NOW_USER_HOSPITAL_INFO_ARR, JSON.stringify(loadedHospital));
    return loadedHospital.find(i => i.id === globalState.userInfo.id);
  }
  return undefined;
};

/** 用户选中医院点击确认进入页面之前需要将 全局变量的医院信息更新 */
export const updateHospitalInfo = () => {
  globalState.userInfo.extendInfo = findLastHospital();
};
