
import { defineComponent, inject, PropType } from 'vue';

export default defineComponent({
  name: 'SwitchEditor',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },
    checkedChildren: {
      type: String,
      default: '是',
    },
    unCheckedChildren: {
      type: String,
      default: '否',
    },
  },
  setup() {},
  computed: {},
  methods: {
    /** 改变值 */
    change(val) {
      this.$emit('update:value', val);
      this.$emit('change', val);
    },
  },
});
