
import { defineComponent, reactive, toRefs, PropType, watch } from 'vue';
import { createModelId, dateFormat, getDataSource } from '@/tools/common';
import { message } from 'ant-design-vue';
import { Chart } from '@antv/g2';
import { storeToRefs } from 'pinia';
import { initChart } from '@/tools/version-manage/chart';
import { useProduct } from '@/modules/version-manage';
import { ComponentChart } from '@/@types/version-manage/component/component-chart';
import { ChartType } from '@/@types/version-manage/enum';

export default defineComponent({
  components: {},
  props: {
    /** 数据 */
    data: {
      type: Object as PropType<ComponentChart<ChartType.pie>>,
      default: () => ({}),
    },
  },
  setup(props) {
    const formState = reactive({
      filter: {},
      chart: {} as Chart,
      /** 图表Id */
      chartId: createModelId(),
      /** 是否加载中 */
      isLoading: false,
      /** 图表数据 */
      dataSource: [] as any[],
      /** 编辑期刊信息Id */
      editUserId: 0,
      /** 是否显示弹出编辑框 */
      editDrawerShow: false,
    });

    /** 图表重绘 */
    const repaint = () => {
      formState.chart.data(formState.dataSource);
      formState.chart.render();
    };

    /** 查询 */
    const search = (): Promise<any> => {
      return new Promise((resolve, reject) => {
        const hide = message.loading('加载中...', 0);
        formState.isLoading = true;

        getDataSource<any[]>(props.data.dataSource)
          .then(d => {
            formState.dataSource = d;
            resolve(d);
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            hide();
            formState.isLoading = false;
          });
      });
    };

    const init = () => {
      formState.chart = initChart(formState.chartId, props.data);

      search().then(repaint);
    };
    /** 重设筛选项 */
    const reset = () => {
      props.data.filterConfig?.filterItems.forEach(filter => {
        formState.filter[filter.name as string] = undefined;
      });
      search().then(repaint);
    };

    /** 监听data */
    watch(
      () => props.data,
      data => {
        if (data) {
          search().then(d => {
            repaint();
          });
        }
      },
    );

    return {
      ...toRefs(formState),
      global: storeToRefs(useProduct()),
      dateFormat,
      search,
      reset,
      init,
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {},
});
