import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditorItem = _resolveComponent("EditorItem")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_empty = _resolveComponent("a-empty")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (['create', 'update'].includes(_ctx.data.operateType))
    ? (_openBlock(), _createBlock(_component_a_drawer, {
        key: 0,
        title: _ctx.data.label,
        width: 720,
        visible: _ctx.visible,
        "footer-style": { textAlign: 'right' },
        onClose: _ctx.onClose
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_a_button, {
            style: {"margin-right":"8px"},
            onClick: _ctx.onClose
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.form?.cancelText || '取消'), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_a_button, {
            loading: _ctx.isLoading,
            type: "primary",
            onClick: _ctx.onSubmit
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.form?.okText || '提交'), 1)
            ]),
            _: 1
          }, 8, ["loading", "onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_spin, {
            tip: "加载中...",
            spinning: _ctx.isLoading
          }, {
            default: _withCtx(() => [
              (_ctx.data.form?.items.length)
                ? (_openBlock(), _createBlock(_component_a_form, {
                    key: 0,
                    ref: "formRef",
                    class: "operte-edit-form",
                    model: _ctx.form,
                    rules: _ctx.rules,
                    layout: "vertical"
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.form?.items, (item) => {
                        return (_openBlock(), _createBlock(_component_a_form_item, {
                          key: `key_${item.name}`,
                          class: _normalizeClass(["operte-edit-item", `operte-edit-item-${item.span || 1}x`]),
                          label: item.label,
                          name: item.name,
                          rules: item.rules
                        }, {
                          default: _withCtx(() => [
                            (Array.isArray(item.name))
                              ? (_openBlock(), _createBlock(_component_EditorItem, {
                                  key: 0,
                                  prop: item,
                                  value: item.name.map(key => _ctx.form[key]),
                                  onChange: _ctx.changeMulValue
                                }, null, 8, ["prop", "value", "onChange"]))
                              : (_openBlock(), _createBlock(_component_EditorItem, {
                                  key: 1,
                                  value: _ctx.form[item.name as string],
                                  "onUpdate:value": ($event: any) => ((_ctx.form[item.name as string]) = $event),
                                  prop: item
                                }, null, 8, ["value", "onUpdate:value", "prop"]))
                          ]),
                          _: 2
                        }, 1032, ["class", "label", "name", "rules"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["model", "rules"]))
                : (_openBlock(), _createBlock(_component_a_empty, {
                    key: 1,
                    description: "暂未配置表单项"
                  }))
            ]),
            _: 1
          }, 8, ["spinning"])
        ]),
        _: 1
      }, 8, ["title", "visible", "onClose"]))
    : _createCommentVNode("", true)
}