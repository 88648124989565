import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "editor-form" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditorItem = _resolveComponent("EditorItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterItems, (filterItem, filterIndex) => {
      return (_openBlock(), _createElementBlock("dl", {
        key: `editor_${filterIndex}`,
        class: "editor-form-item"
      }, [
        (filterItem.label)
          ? (_openBlock(), _createElementBlock("dt", _hoisted_2, _toDisplayString(filterItem.label), 1))
          : _createCommentVNode("", true),
        _createElementVNode("dd", null, [
          (Array.isArray(filterItem.name))
            ? (_openBlock(), _createBlock(_component_EditorItem, {
                key: 0,
                prop: filterItem,
                value: filterItem.name.map(key => _ctx.getPageFilter[key]),
                onChange: _ctx.changeMulValue
              }, null, 8, ["prop", "value", "onChange"]))
            : (_openBlock(), _createBlock(_component_EditorItem, {
                key: 1,
                value: _ctx.getPageFilter[filterItem.name],
                "onUpdate:value": ($event: any) => ((_ctx.getPageFilter[filterItem.name]) = $event),
                prop: filterItem
              }, null, 8, ["value", "onUpdate:value", "prop"]))
        ])
      ]))
    }), 128))
  ]))
}