
import { defineComponent, PropType, reactive, toRefs } from 'vue';

export default defineComponent({
  name: 'WidthEditor',
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  setup() {
    const state = reactive({
      /** 值 */
      inputValue: '',
      /** 后缀 */
      suffix: 'px',
    });
    return {
      ...toRefs(state),
    };
  },
  mounted() {
    this.init();
  },
  unmounted() {
    // if (this.inputValue != this.value) {
    //   this.$emit('change', this.inputValue);
    // }
  },
  methods: {
    /** 初始化 */
    init() {
      if (typeof this.value === 'string') {
        const _suffixRegExp = /[^-0-9.]/.exec(this.value);
        if (_suffixRegExp) {
          this.inputValue = this.value.substring(0, _suffixRegExp.index);
          this.suffix = this.value.substring(_suffixRegExp.index);
        } else {
          this.inputValue = this.value;
        }
      } else {
        this.inputValue = this.value;
      }
    },
    /** 改变值 */
    change() {
      if (this.inputValue + this.suffix !== this.value) {
        this.$emit('change', this.inputValue + this.suffix);
      }
    },
  },
});
