
import { getProductList, getProjectDetail, searchProjectList, getBranchList, gitCommitList, getProductDetail } from '@/api/version-manage';
import { useProduct } from '@/modules/version-manage';
import { post } from '@/lib/api';
import { AsyncReturnType } from '@/@types';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { message, Modal } from 'ant-design-vue';
import { defineComponent, reactive, toRefs, ref, watch, onMounted, computed, createVNode, h } from 'vue';
import { showPVNotification } from '@/modules/version-manage/show-pv-notification';

export default defineComponent({
  components: {},
  props: {
    /** 是否为创建 */
    isCreate: {
      type: Boolean,
      default: true,
    },
    /** 是否显示 */
    visible: {
      type: Boolean,
      default: false,
    },
    /** 是否禁用 */
    disabled: {
      type: Boolean,
      default: false,
    },
    /** 默认产品Id */
    productId: {
      type: Number,
    },
    /** 默认项目Id */
    projectId: {
      type: Number,
    },
    /** jenkins配置 */
    getJenkensConfig: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const global = useProduct();

    const formRef = ref();

    /** 默认表单数据 */
    const defaultForm = {
      /** jenkines配置传递 */
      jenkinsExtraConfig: {} as Record<string, any> | string,
      /** 产品Id */
      productId: undefined as number | undefined,
      /** 项目Id */
      projectId: undefined as number | undefined,
      /** 继承版本信息Ids（建议自动填充） */
      prevVersionIds: [],
      /** 分支Id */
      branchId: '',
      /** 分支名称 */
      branchName: '',
      /** 版本号 */
      version: '',
      /** git提交号 */
      commitNum: undefined,
      /** 运行发布环境 */
      environment: 'test',
      /** 是否启用 */
      enable: true,
      /** 是否强制更新 */
      forceUpgrade: false,
      /** 是否发布 */
      release: true,
      /** 是否预约发布 */
      isReserveRelease: false,
      /** 预约发布时间 */
      releaseDate: '',
      /** 更新内容 */
      updateContent: '',
      /** 存在问题及改进建议 */
      issue: '',
      /** 版本信息备注 */
      productVersionRemark: '',
      /** 工单备注 */
      workOrderRemark: '',
    };

    const state = reactive({
      /** Git提交记录是否加载中 */
      commitNumLoading: false,
      /** 使用Jenkins构建 */
      useJenkinsConstruct: false,
      /** 是否加载中 */
      isLoading: false,
      /** 表单 */
      form: { ...defaultForm } as typeof defaultForm,
      /** 产品列表 */
      productList: [] as AsyncReturnType<typeof getProductList>['rows'],
      /** 产品明细列表 */
      productDetailList: [] as Record<string, any>[],
      /** 产品分支列表 */
      productBranchList: [] as Record<string, any>[],
      /** 文件列表 */
      fileList: [] as any[],
      /** 最小版本号 */
      minVersion: '',
      /** 提交进度 */
      submitProgress: 0,
      /** Git提交列表 */
      gitSubmitList: [] as any[],

      /** Jenkins是否构建成功 */
      isBuildComplete: false,
      /** Jenkins预计构建时间，默认60秒 */
      buildExpectTime: 60000,
      /** Jenkins构建号 */
      buildNumber: '',
      /** Jenkins版本Id */
      versionId: '',
      /** Jenkins构建进度 */
      buildPercent: 0,
      /** 任务启动时间 */
      timestamp: 0,
      /** 上次构建预估时间 */
      estimatedDuration: 0,
    });

    /** 获取产品版本列表 */
    const getProductVesions = computed(() => {
      if (!state.form.projectId && !state.productDetailList?.length) return [];
      const _versionList = state.productDetailList?.find?.(i => i.id === state.form.projectId)?.programVerProjectVersionVos;
      if (!_versionList?.length) return [];
      return _versionList?.filter(
        i =>
          i.environment === 'production' &&
          // i.environment === global.environment.production &&
          i.enable === true &&
          i.release === true &&
          i.deleted === false &&
          i.publishState === 'complete' &&
          i.branchId === state.form.branchId,
      );
    });

    /** 表单规则 */
    const rules = {
      productId: [{ required: true, message: '请选择关联产品' }],
      branchName: [{ required: true, message: '请选择关联分支' }],
      projectId: [{ required: true, message: '请选择关联产品明细' }],
      file: [{ required: true, message: '请上传文件' }],
      commitNum: [{ required: true, message: '请选择Git提交号' }],
      version: [{ required: true, message: '请填写版本号' }],
      releaseDate: [
        {
          get required() {
            return !!state.form.isReserveRelease;
          },
          message: '请选择预约发布时间',
        },
      ],
      updateContent: [{ required: true, message: '请填写升级说明' }],
      prevVersionIds: [
        {
          get required() {
            return !!getProductVesions.value.length;
          },
          message: '请选择继承版本',
        },
      ],
    };

    /** 获取产品类型列表 */
    const getProductTypes = computed(() => {
      if (!state?.productDetailList?.length) return [];
      const _data = {};
      state.productDetailList.forEach(i => {
        if (!_data[i.productType]) {
          _data[i.productType] = i.id;
        }
      });
      return _data;
    });

    /** 获取当前已选择产品 */
    const selectedProject = computed(() => {
      if (state.form.projectId) {
        return state.productDetailList.find(i => i.id === state.form.projectId);
      } else {
        return {};
      }
    });

    /** 当前已选择产品类型 */
    const getSelectedProductType = computed(() => {
      if (!state?.form?.projectId || !state?.productDetailList?.length) return {};
      return state?.productDetailList.find(i => i.id === state.form.projectId) || {};
    });

    /** 获取发布环境列表 */
    const getEnvironmentList = computed(() => {
      if (state.form.projectId) {
        if (state.useJenkinsConstruct) {
          return ['test'];
        } else {
          return Object.keys(global.environment);
        }
      }
      return [];
    });

    /** 清空数据 */
    const clear = () => {
      state.form = { ...defaultForm };
      if (props.projectId && props.productId) {
        state.form.projectId = props.projectId;
        state.form.productId = props.productId;
      }
    };

    /** 1、获取git提交列表 */
    const searchGitSubmitList = (): Promise<void> => {
      return new Promise((resolve, reject) => {
        if (!state.useJenkinsConstruct) {
          resolve();
          return;
        }
        state.commitNumLoading = true;
        gitCommitList(state.form.projectId as number)
          .then(d => {
            const _data = JSON.parse(d);
            if (_data && _data.errors.length === 0) {
              state.gitSubmitList = _data.values.map(i => ({
                ...i,
                data: (() => {
                  // git 提交记录数据显示的模式 有 修订模式 分支模式 分支和标签模式 标签模式 这四种模式
                  // 修改模式数据显示是 显示为 时间 人员 描述
                  // 其余三种模式数据显示是 name 字段

                  // 此为修订模式显示字段
                  if (i.name.includes(' ')) {
                    const _splitData = i.name.split(' ');
                    if (!_splitData || _splitData.length < 5) return {};
                    const [shortNo, shortDate, shortTime, submitUser, submitEmail] = _splitData;
                    return {
                      // 下拉列表选择传输的真实数据值 shortNo
                      shortNo,
                      shortDate,
                      shortTime,
                      submitUser,
                      submitEmail,
                      description: _splitData.filter((_, index) => index >= 5).join(' '),
                    };
                  }

                  // 此为非修订模式显示字段
                  return {
                    // 下拉列表选择传输的真实数据值 shortNo
                    shortNo: i.value,
                    shortDate: i.name,
                  };
                })(),
              }));
              resolve();
            } else {
              message.error(_data.errors[0]);
              reject(_data.errors);
            }
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            state.commitNumLoading = false;
          });
      });
    };

    /** 获取新版本号 */
    const getNewVersion = () => {
      return new Promise((resolve, reject) => {
        getProjectDetail({
          id: state.form.projectId,
        })
          .then(data => {
            if (!data) {
              resolve('');
            } else {
              const _item = data.programVerProjectVersionVos.find(
                i => i.environment === state.form.environment && i.branchId === Number(state.form.branchId),
              );
              if (_item) {
                const _minVersion = _item?.version;
                state.form.version = _minVersion;
                state.minVersion = _minVersion;
                resolve(_minVersion);
              }
            }
            resolve('');
          })
          .catch(err => {
            reject(err);
          });
      });
    };
    /** 修改选择项目明细 */
    const onChange_ProductDetail = () => {
      // 根据jenkins配置判断提交新版本方式
      const _detailProduct = state.productDetailList.find(i => i.id === state.form.projectId);
      if (_detailProduct?.jenkinsConfig) {
        state.useJenkinsConstruct = _detailProduct.jenkinsConfig.jenkinsCode && _detailProduct.jenkinsConfig.jenkinsToken;
      }
      searchGitSubmitList();
      getNewVersion();
    };
    /** 查询项目详情列表 */
    const searchProductDetailList = (): Promise<any> => {
      return Promise.all([
        // 获取项目详情列表
        new Promise((resolve, reject) => {
          searchProjectList({
            pageNum: 1,
            pageSize: 9999,
            productId: state.form.productId as number,
          })
            .then(d => {
              const _data = d || [];
              state.productDetailList = _data;
              // 如果没有则取一个默认值
              if (!state.form.projectId && _data?.length) {
                state.form.projectId = _data[0].id;
                onChange_ProductDetail();
              }
              resolve(_data);
            })
            .catch(err => {
              reject(err);
            });
        }),
        // 获取产品分支列表
        new Promise((resolve, reject) => {
          getBranchList({
            pageNum: 1,
            pageSize: 9999,
            productId: state.form.productId as number,
          })
            .then(d => {
              state.productBranchList = d.rows;
              // 如果没有则取一个默认值
              if (!state.form.branchName && d?.rows.length) {
                state.form.branchName = d.rows[0].branchName;
              }
              resolve(d);
            })
            .catch(err => {
              reject(err);
            });
        }),
      ])
        .then(d => {
          getNewVersion();
        })
        .finally(() => {});
    };

    /** 改变发布环境 */
    const changeEnvironment = () => {
      getNewVersion();
    };

    /** 改变分支 */
    const changeBranch = () => {
      state.form.branchId = state.productBranchList.find(i => i.branchName === state.form.branchName)?.id || '';
      getNewVersion();
    };

    /** 修改选择项目 */
    const productChange = () => {
      searchProductDetailList();
    };

    /** 修改是否发布 */
    const onChange_isRelease = () => {
      if (!state.form.release && state.form.isReserveRelease) {
        state.form.isReserveRelease = false;
        state.form.releaseDate = '';
      }
    };

    /** 修改是否预约发布 */
    const onChange_isReserveRelease = () => {
      if (!state.form.isReserveRelease) {
        state.form.releaseDate = '';
      }
    };

    watch(
      () => props.visible,
      (count, prevCount) => {
        if (count) {
          if (props.projectId && props.productId) {
            state.form.projectId = props.projectId;
            state.form.productId = props.productId;
            searchProductDetailList().then(d => {
              onChange_ProductDetail();
            });
          }
          if (props.isCreate && state.form.productId) {
            state.isLoading = true;
            getProductDetail({
              id: state.form.productId,
            })
              .then(data => {
                state.form = { ...state.form, ...data };
              })
              .finally(() => {
                state.isLoading = false;
              });
          } else {
            clear();
          }
        }
      },
    );

    onMounted(() => {
      // 查询产品列表
      getProductList({
        pageNum: 1,
        pageSize: 9999,
      })
        .then(data => {
          if (data) {
            state.productList = data.rows;
          }
        })
        .finally(() => {
          state.isLoading = false;
        });
    });

    /** 修改上传文件 */
    const changeUploadFile = () => {};
    /** 拖拽上传文件 */
    const handleDrop = (e: DragEvent) => {
      console.log(e);
    };

    return {
      ...toRefs(state),
      global,
      formRef,
      rules,
      clear,
      productChange,
      getProductTypes,
      getSelectedProductType,
      getEnvironmentList,
      getProductVesions,
      changeEnvironment,
      changeBranch,
      changeUploadFile,
      handleDrop,
      onChange_ProductDetail,
      onChange_isRelease,
      onChange_isReserveRelease,
      selectedProject,
    };
  },
  methods: {
    onClose() {
      return new Promise((resolve, reject) => {
        Modal.confirm({
          title: '是否放弃提交新版本？',
          icon: createVNode(ExclamationCircleOutlined),
          content: createVNode('div', { style: 'color:red;' }, '注意：系统不会保留当前的编辑记录'),
          onOk: () => {
            this.clear();
            this.$emit('update:visible', false);
            resolve(true);
          },
          onCancel() {
            reject(new Error());
          },
        });
      });
    },
    onSubmit() {
      // /app/workOrder/add/file // x、提交工单传文件

      // /app/git/commit // 1、获取git提交列表
      // /program/ver/version/check // 2、提交工单校验
      // /program/ver/version/add/by/git // 3、提交工单
      // /system/program/ver/version/build/schedule // 4、查询构建进度
      // /app/product/build // 5、查询是否成功构建

      this.isLoading = true;
      this.buildPercent = 0;
      this.formRef
        .validate()
        .then((): Promise<void> => {
          return new Promise((resolve, reject) => {
            const hide = message.loading('工单校验中...');
            post('/system/program/ver/version/check', {
              ...this.form,
            })
              .then(d => {
                resolve();
              })
              .catch(err => {
                reject(err);
              })
              .finally(() => {
                this.isLoading = false;
                hide();
              });
          });
        })
        .then((): Promise<void> => {
          return new Promise((resolve, reject) => {
            post('/system/program/ver/version/add/by/git', {
              ...this.form,
            })
              .then(data => {
                this.buildNumber = data.buildNumber;
                this.versionId = data.id;
                this.$emit('update:visible', false);
                this.$emit('submit', data);
                resolve();
              })
              .catch(err => {
                reject(err);
              })
              .finally(() => {
                this.isLoading = false;
              });
          });
        })
        .then(() =>
          showPVNotification(
            {
              buildPercent: 0,
              id: this.versionId,
              isBuildComplete: false,
              timestamp: 0,
              estimatedDuration: 0,
            },
            {
              environmentText: '已发布到测试环境',
              emitCallback: () => this.$emit('complete'),
              closeText: '版本发布成功。',
            },
          ),
        )
        .catch(() => {
          this.isLoading = false;
          message.info('数据格式不正确或者不完整');
        });
    },
  },
});
