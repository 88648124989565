import { ApiReturn, get, post, put, deletes } from '@/lib/api';
import { IGetRoutes as IGetRes } from '@typings/responses/routes';

type GetCodeRes = Promise<ApiReturn & IGetRes['/code']>;

/** 登录方法 */
export function login(username, password, code, uuid) {
  return post('/auth/authApi/login', { account: username, password, code, uuid, loginMode: '00' });
}

/** 注册方法 */
export function register(data) {
  return post('/auth/register', data, { getToken: false, returnResponse: true });
}

// 刷新Token方法
export function refreshToken() {
  return post('/auth/refresh', {}, { returnResponse: true });
}

/** 获取用户详细信息 */
export function getInfo(id?: number) {
  return get('/system/user/getInfo', {}, { returnResponse: true, headers: id === undefined ? {} : { 'Hospital-Info': id } });
}

/** 退出方法 */
export function logout() {
  return deletes('/auth/logout', {}, { returnResponse: true });
}

/** 获取验证码 */
export function getCodeImg() {
  return get('/code', { timeout: 20000 }, { getToken: false, returnResponse: true }) as GetCodeRes;
}
