import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tree_select = _resolveComponent("a-tree-select")!

  return (_openBlock(), _createBlock(_component_a_tree_select, {
    ref: "editor",
    modelValue: _ctx.inputValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
    treeExpandedKeys: _ctx.extendNodes,
    "onUpdate:treeExpandedKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.extendNodes) = $event)),
    searchValue: _ctx.searchTxt,
    "onUpdate:searchValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchTxt) = $event)),
    placeholder: "请选择页面变量",
    "search-place-holder": "搜索变量",
    size: _ctx.size,
    "tree-node-filter-prop": "name",
    "tree-node-label-prop": "remark",
    "tree-data": _ctx.variableList,
    class: "variable-picker",
    "allow-clear": true,
    "show-search": true,
    "filter-tree-node": _ctx.search,
    "dropdown-style": {
      maxHeight: '500px',
    },
    onChange: _ctx.change
  }, null, 8, ["modelValue", "treeExpandedKeys", "searchValue", "size", "tree-data", "filter-tree-node", "onChange"]))
}