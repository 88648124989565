import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["value"]
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["value"]
const _hoisted_5 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.type === 'string')
      ? (_openBlock(), _createElementBlock("input", {
          key: 0,
          type: "text",
          value: _ctx.value,
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args)))
        }, null, 40, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.type === 'array')
      ? (_openBlock(), _createElementBlock("input", {
          key: 1,
          type: "text",
          value: _ctx.value,
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args)))
        }, null, 40, _hoisted_2))
      : (_ctx.type === 'number')
        ? (_openBlock(), _createElementBlock("input", {
            key: 2,
            type: "number",
            value: _ctx.value,
            onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args)))
          }, null, 40, _hoisted_3))
        : (_ctx.type === 'boolean')
          ? (_openBlock(), _createElementBlock("input", {
              key: 3,
              type: "checkbox",
              value: _ctx.value,
              onInput: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args)))
            }, null, 40, _hoisted_4))
          : (_ctx.type === 'date')
            ? (_openBlock(), _createElementBlock("input", {
                key: 4,
                type: "datetime",
                value: _ctx.dateFormat(_ctx.value, 'yyyy-MM-dd'),
                onChange: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.change && _ctx.change(...args)))
              }, null, 40, _hoisted_5))
            : _createCommentVNode("", true)
  ]))
}