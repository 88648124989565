import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_alert = _resolveComponent("a-alert")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_drawer, {
      title: _ctx.branchId ? '产品分支编辑' : '产品分支新增',
      width: 720,
      visible: _ctx.visible,
      "footer-style": { textAlign: 'right' },
      onClose: _ctx.onClose
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_a_button, {
          style: {"margin-right":"8px"},
          onClick: _ctx.onClose
        }, {
          default: _withCtx(() => [
            _createTextVNode("取消")
          ]),
          _: 1
        }, 8, ["onClick"]),
        (!_ctx.disabled)
          ? (_openBlock(), _createBlock(_component_a_button, {
              key: 0,
              loading: _ctx.isLoading,
              type: "primary",
              onClick: _ctx.onSubmit
            }, {
              default: _withCtx(() => [
                _createTextVNode("提交")
              ]),
              _: 1
            }, 8, ["loading", "onClick"]))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_a_spin, {
          tip: "加载中...",
          spinning: _ctx.isLoading
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["check-form", { disabled: _ctx.disabled }])
            }, [
              _createVNode(_component_a_alert, {
                style: {"margin-bottom":"20px"},
                message: "分支名称只能由[英文字母/数字/下划线组成]，且创建分支后不可修改分支名称",
                type: "warning",
                "show-icon": ""
              }),
              _createVNode(_component_a_form, {
                ref: "formRef",
                model: _ctx.form,
                rules: _ctx.rules,
                layout: "vertical"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_row, { gutter: 16 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            label: "关联产品",
                            name: "productId"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_select, {
                                value: _ctx.form.productId,
                                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.productId) = $event)),
                                "allow-clear": "",
                                style: {"width":"100%"},
                                placeholder: "请选择关联产品"
                              }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productList, (item) => {
                                    return (_openBlock(), _createBlock(_component_a_select_option, {
                                      key: item.id,
                                      value: item.id,
                                      "onUpdate:value": ($event: any) => ((item.id) = $event),
                                      label: item.id
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.productName), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["value", "onUpdate:value", "label"]))
                                  }), 128))
                                ]),
                                _: 1
                              }, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { gutter: 16 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          (!!_ctx.branchId)
                            ? (_openBlock(), _createBlock(_component_a_form_item, {
                                key: 0,
                                label: "分支名称"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    bordered: false,
                                    value: _ctx.form.branchName
                                  }, null, 8, ["value"])
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_a_form_item, {
                                key: 1,
                                label: "分支名称",
                                name: "branchName"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_a_input, {
                                    value: _ctx.form.branchName,
                                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.branchName) = $event))
                                  }, null, 8, ["value"])
                                ]),
                                _: 1
                              }))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_a_row, { gutter: 16 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_col, { span: 24 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_form_item, {
                            label: "分支描述",
                            name: "description"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_textarea, {
                                value: _ctx.form.description,
                                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.description) = $event)),
                                rows: 3
                              }, null, 8, ["value"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model", "rules"])
            ], 2)
          ]),
          _: 1
        }, 8, ["spinning"])
      ]),
      _: 1
    }, 8, ["title", "visible", "onClose"])
  ]))
}