import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.imageUrl)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.myclass)
        }, [
          _createElementVNode("img", {
            src: _ctx.imageUrl,
            class: _normalizeClass(_ctx.imgclass),
            crossorigin: "anonymous"
          }, null, 10, _hoisted_1)
        ], 2))
      : _createCommentVNode("", true)
  ]))
}