
import {
  computed,
  defineComponent,
  inject,
  onBeforeUnmount,
  onMounted,
  PropType,
  reactive,
  ref,
  toRefs,
  watch,
  watchEffect,
  watchSyncEffect,
} from 'vue';
// import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
let editor: any;
let subscription: any;

export default defineComponent({
  name: 'CodeEditor',
  props: {
    value: {
      type: String,
      required: true,
    },
    marker: {
      type: Function,
    },
    /** 语言类型 */
    language: {
      type: String,
      default: 'json',
    },
    /** 是否为表达式 */
    isExpression: {
      type: Boolean,
      default: false,
    },
    /** 提示文本 */
    placeholder: {
      type: String,
      default: '请输入JSON字符串',
    },
    /** 改变事件 */
    onChange: {
      type: Function as PropType<(value: string) => void>,
    },
    /** 附加参数 */
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const state = reactive({
      content: '',
      /** TS转JS的数据 */
      realJavascript: '',
      defaultOptions: {
        wordWrap: 'off', // 控制如何换行
        automaticLayout: true,
        fontFamily: "'Fira Code', Consolas, 'Courier New', monospace, 'Microsoft YaHei'",
      },
      /** 停止绘制 */
      preventTriggerChangeEvent: false,
    });

    /** 获取值 */
    const getValue = () => {
      return editor?.getValue?.() || '';
    };
    /** 销毁 */
    const destory = () => {
      if (subscription) subscription.dispose();
      const model = editor.getModel();
      if (model) model.dispose();
      editor.dispose();
    };
    /** 监听值 */
    watch(
      () => props.value,
      (count, prevCount) => {
        if (editor) {
          if (count !== editor.getValue()) {
            editor.setValue(count);
          }
        }
      },
    );

    if (props.isExpression) {
      window.monaco.editor.defineTheme('gj-dark', {
        base: 'vs-dark',
        inherit: true,
        rules: [{ token: 'custom-variable', foreground: 'ffa500', fontStyle: 'underline' }],
        colors: {},
      });

      // validation settings
      window.monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
        noSemanticValidation: true,
        noSyntaxValidation: false,
      });

      // compiler options
      window.monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
        target: window.monaco.languages.typescript.ScriptTarget.ESNext,
        allowNonTsExtensions: true,
      });
    } else {
      window.monaco.editor.defineTheme('gj-dark', {
        base: 'vs-dark',
        inherit: true,
        rules: [{ token: 'custom-variable', foreground: 'ffa500', fontStyle: 'underline' }],
        colors: {},
      });

      const _languages = window.monaco.languages.getLanguages().map(i => i.id);
      if (!_languages.includes(props.language)) {
        switch (props.language) {
          case 'sql':
            break;
          default:
            break;
        }
      }
    }

    onBeforeUnmount(() => {
      destory();
    });

    return {
      ...toRefs(state),
      editor,
      getValue,
    };
  },
  computed: {},
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      // onDidChangeOptions 配置改变事件
      // onDidChangeLanguage 语言改变事件

      const _options = {
        ...this.defaultOptions,
        ...this.options,
        theme: 'gj-dark',
        language: this.language,
      };

      editor = window.monaco.editor.create(this.$el, {
        value: this.value,
        ..._options,
        wordWrap: 'on',
      });

      this.$emit('ready', editor);

      this.$nextTick(() => {
        editor.onDidBlurEditorText(() => {
          if (!this.preventTriggerChangeEvent) {
            this.$emit('update:value', this.getValue());
            // this.$emit('input', this.getValue());
          }
        });
        editor.onDidFocusEditorText(() => {
          this.$emit('focus');
        });

        subscription = editor.onDidChangeModelContent(event => {
          if (!this.preventTriggerChangeEvent) {
            this.$emit('update:value', editor.getValue(), event);
          }
        });
      });
    },
  },
});
