import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({
      flexShrink: _ctx.data.flexShrink ?? 1,
      flexGrow: _ctx.data.flexGrow ?? 1,
      width: '100%',
    })
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.data.child.component), {
      data: _ctx.data.child
    }, null, 8, ["data"]))
  ], 4))
}