
import { ComponentFlexItem } from '@/@types/version-manage/component/component-flex';
import { useProduct } from '@/modules/version-manage';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: {},
  props: {
    /** 数据 */
    data: {
      type: Object as PropType<ComponentFlexItem>,
      default: () => ({}),
    },
  },
  setup() {
    const global = useProduct();

    return {
      global,
    };
  },
  created() {},
  mounted() {},
  methods: {},
});
