
import { defineComponent, PropType, reactive, ref, toRefs } from 'vue';

export default defineComponent({
  name: 'ColorPickerSlider',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    sliderStyle: {
      type: Object as PropType<Record<string, any>>,
    },
    max: {
      type: Number,
      default: 100,
    },
  },
  setup() {
    /** 控件画布 */
    const slider = ref({} as any);

    const state = reactive({
      /** 是否开始拖拽 */
      isStartDrag: false,
    });

    return {
      ...toRefs(state),
      /** 滑块元素 */
      slider,
    };
  },
  computed: {
    /** 游标离左侧距离 */
    cursorLeft(): number {
      return ((this.slider.offsetWidth || 0) * this.value) / this.max - 8;
    },
  },
  unmounted() {
    document.body.removeEventListener('mousemove', this.drag);
    document.body.removeEventListener('mouseup', this.endDrag);
  },
  mounted() {
    this.init();
  },
  methods: {
    startDrag(e) {
      this.isStartDrag = true;
      this.drag(e);
    },
    drag(e) {
      if (this.isStartDrag) {
        const rect = this.slider.getBoundingClientRect();
        const _cursorLeft = Math.min(Math.max(0, e.pageX - rect.left), rect.width);
        const _value = Math.round((_cursorLeft / this.slider.offsetWidth) * this.max);
        this.$emit('input', _value);
        this.$emit('update:value', _value);
      }
    },
    endDrag() {
      this.isStartDrag = false;
    },
    /** 初始化 */
    init() {
      document.body.addEventListener('mousemove', this.drag);
      document.body.addEventListener('mouseup', this.endDrag);
    },
  },
});
